import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { getDepositJourneyLabel } from '../../../../utils/reserveNowUtils';
import { DepositStatuses } from '../../../../constants';

const DepositStatusBadge = ({ deposit }) => {
  if (!deposit) return null;

  const getColor = () => {
    const { status } = deposit;
    if (status === DepositStatuses.SUBMITTED) return 'primary';
    if (status === DepositStatuses.SUCCEEDED) return 'primary';
    if (status === DepositStatuses.ACCEPTED) return 'primary';
    if (status === DepositStatuses.REJECTED) return 'danger';
    return 'light';
  };

  const color = useMemo(getColor, [deposit]);

  return (
    <Badge color={color} pill>
      {getDepositJourneyLabel(deposit)}
    </Badge>
  );
};

DepositStatusBadge.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any)
};

DepositStatusBadge.defaultProps = {
  deposit: null,
};

export default DepositStatusBadge;
