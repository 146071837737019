import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup, Input, Label, Collapse
} from 'reactstrap';
import i18n from 'i18n-js';
import Switch from 'rc-switch';

import { parseBoolean } from '../../../../../utils/parserUtils';

const i18nOpts = { scope: 'components.admin.companies.form.reserveNowSettings.index' };

const ReserveNowSettings = ({ form, onChange }) => {
  const { buyNowSettings: settings = {} } = form;

  const onToggle = (name) => {
    const { buyNowSettings = {} } = form;
    buyNowSettings[name] = !buyNowSettings[name];
    if (name === 'enabledOffers' && !buyNowSettings[name]) {
      buyNowSettings.enabledMakeOffer = false;
      buyNowSettings.enabledPriceNegotiation = false;
    }
    onChange(buyNowSettings);
  };

  return (
    <div className="mb-4">
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            name="enableReserveNow"
            id="enableReserveNow"
            value={!parseBoolean(settings.enabled)}
            checked={parseBoolean(settings.enabled)}
            onChange={() => onToggle('enabled')}
          />
          {i18n.t('enabled', i18nOpts)}
        </Label>
      </FormGroup>

      <Collapse isOpen={settings.enabled || false} className="pl-4 pt-2">
        <div className="d-inline-block">
          <span onClick={() => onToggle('enabledOffers')} role="button" aria-hidden className="d-flex align-items-center">
            <Switch checked={settings.enabledOffers || false} />
            <span className="ml-2">{i18n.t('offers', i18nOpts)}</span>
          </span>
        </div>
        <br />
        <Collapse isOpen={settings.enabledOffers || false}>
          <div className="d-inline-block mt-3">
            <span onClick={() => onToggle('enabledMakeOffer')} role="button" aria-hidden className="d-flex align-items-center">
              <Switch checked={settings.enabledMakeOffer || false} />
              <span className="ml-2">{i18n.t('makeOffer', i18nOpts)}</span>
            </span>
          </div>
          <br />
          <div className="d-inline-block mt-3">
            <span onClick={() => onToggle('enabledPriceNegotiation')} role="button" aria-hidden className="d-flex align-items-center">
              <Switch checked={settings.enabledPriceNegotiation || false} />
              <span className="ml-2">{i18n.t('priceNegotiation', i18nOpts)}</span>
            </span>
          </div>
        </Collapse>
        <div className="d-inline-block mt-3">
          <span onClick={() => onToggle('enabledDeposits')} role="button" aria-hidden className="d-flex align-items-center">
            <Switch checked={settings.enabledDeposits || false} />
            <span className="ml-2">{i18n.t('deposits', i18nOpts)}</span>
          </span>
        </div>
        <br />
        <div className="d-inline-block mt-3">
          <span onClick={() => onToggle('enabledReports')} role="button" aria-hidden className="d-flex align-items-center">
            <Switch checked={settings.enabledReports || false} />
            <span className="ml-2">{i18n.t('reports', i18nOpts)}</span>
          </span>
        </div>
      </Collapse>
    </div>
  );
};

ReserveNowSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

ReserveNowSettings.defaultProps = {
  onChange: () => {}
};

export default ReserveNowSettings;
