import { GET_DEPOSIT, GET_DEPOSITS, SAVE_DEPOSIT } from './types';
import api from '../../api';
import { getDepositQuery, listDepositsQuery, updateDepositQuery } from '../../graphql';

export function getDeposit(id) {
  return {
    type: GET_DEPOSIT,
    payload: api.graphql(getDepositQuery, { id })
  };
}

export function getDeposits(variables = {}) {
  return {
    type: GET_DEPOSITS,
    payload: api.graphql(listDepositsQuery, variables)
  };
}

export function saveDeposit(variables) {
  return {
    type: SAVE_DEPOSIT,
    payload: api.graphql(updateDepositQuery, variables)
  };
}
