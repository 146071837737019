import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';
import i18n from 'i18n-js';
import moment from 'moment';
import v from 'voca';
import classNames from 'classnames';

import styles from './HomeCard.module.scss';
import { getHomeInfo } from '../../../utils/homeUtils';
import Price from '../home/Price';
import ValidUntil from '../home/ValidUntil';
import Beds from '../home/Beds';
import Baths from '../home/Baths';
import Size from '../home/Size';
import { isRecently } from '../../../utils/dateUtils';
import { DateFormats } from '../../../constants';

const i18nOpts = { scope: 'components.global.homeCard.index' };

const HomeCard = ({
  home, hidePrice, hideBuiltDate, hideTotalOffers
}) => {
  if (!home) return null;

  const {
    homeUrl, sizeSqft, deletedByUserAt, totalOffers
  } = home;
  const {
    name, imageUrl, isQuickPossession, isLot, address
  } = getHomeInfo(home);

  return (
    <a href={homeUrl} target="_blank" rel="noreferrer" className="d-flex gap-3 col-gray-600">
      {!v.isBlank(imageUrl) && (
        <div>
          <div className={styles.imageContainer}>
            <img src={imageUrl} alt={name} className={styles.image} />
          </div>
        </div>
      )}

      <div>
        <div className="mb-1">
          <div className="d-flex align-items-center gap-2">
            <div className="col-gray-900 font-weight-500">{name}</div>
            {isQuickPossession && (
              <Badge color="warning" pill>{i18n.t('quickPossession', i18nOpts)}</Badge>
            )}
          </div>

          {!v.isBlank(address) && (
            <div>
              <small>{address}</small>
            </div>
          )}

          {!hidePrice && (
            <div>
              <Price amount={home.totalPrice} className="mr-1 col-gray-900 font-weight-500" />
              <ValidUntil home={home} />
            </div>
          )}
        </div>

        {!isLot && (
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <Size>{sizeSqft}</Size>
            <Beds>{home.beds}</Beds>
            <Baths>{home.baths}</Baths>
          </div>
        )}

        {!hideTotalOffers && isQuickPossession && (
          <div>
            {i18n.t('totalOffers', { ...i18nOpts, total: totalOffers })}
          </div>
        )}

        {!hideBuiltDate && (
          <div className="mt-1">
            {i18n.t('builtOn', i18nOpts)}
            <span className={classNames({ 'text-success': isRecently(home.createdAt) })}>
              {moment(home?.createdAt).format(DateFormats.MMM_DD_YYYY)}
            </span>
          </div>
        )}

        { !v.isBlank(deletedByUserAt) && (
          <div>
            <span className="text-danger">{i18n.t('deletedByUserAt', i18nOpts)}</span>
            <span>
              {moment(home?.deletedByUserAt).format(DateFormats.MMM_DD_YYYY)}
            </span>
          </div>
        )}
      </div>
    </a>
  );
};

HomeCard.propTypes = {
  home: PropTypes.objectOf(PropTypes.any),
  hidePrice: PropTypes.bool,
  hideBuiltDate: PropTypes.bool,
  hideTotalOffers: PropTypes.bool
};

HomeCard.defaultProps = {
  home: null,
  hidePrice: false,
  hideBuiltDate: false,
  hideTotalOffers: false,
};

export default HomeCard;
