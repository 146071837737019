import React from 'react';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';

const i18nOpts = { scope: 'components.admin.workflows.buyerReserveWorkflow.realtorQuestionStep.index' };

const RealtorQuestionStep = () => (
  <Card body className="mb-3">
    <div className="d-flex align-items-center justify-content-end">
      <span className="mr-3 font-weight-600">{i18n.t('title', i18nOpts)}</span>
    </div>
  </Card>
);

RealtorQuestionStep.propTypes = {};

RealtorQuestionStep.defaultProps = {};

export default RealtorQuestionStep;
