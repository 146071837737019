import React from 'react';
import {
  Col, Collapse, FormGroup, Input, Label, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';
import PropTypes from 'prop-types';

import ColorPicker from '../../../common/ColorPicker';
import { isAdmin } from '../../../../utils/authUtils';
import { ValidUntilPeriods } from '../../../../constants';
import { parseBoolean } from '../../../../utils/parserUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.design.general.index' };

const General = ({
  form, validUntil, onTextChange, onChangeColor, onValidUntilChange, error, currentCompany
}) => {
  const isPriceNegotiationEnabled = currentCompany?.buyNowSettings?.enabledPriceNegotiation;

  return (
    <div>
      <Row className="mb-3">
        <Col xs="8" sm="7" md="4" lg="3">
          {i18n.t('mainColor', i18nOpts)}
        </Col>
        <Col xs="4" sm="5" md="8" lg="9">
          {!v.isBlank(form.mainColor) && (
            <ColorPicker
              initialColor={form.mainColor}
              onChange={(color) => onChangeColor('mainColor', color)}
            />
          )}
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs="8" sm="7" md="4" lg="3">
          {i18n.t('accentColor', i18nOpts)}
        </Col>
        <Col xs="4" sm="5" md="8" lg="9">
          {!v.isBlank(form.accentColor) && (
            <ColorPicker
              initialColor={form.accentColor}
              onChange={(color) => onChangeColor('accentColor', color)}
            />
          )}
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs="8" sm="7" md="4" lg="3">
          {i18n.t('floorPlanHighlightColor', i18nOpts)}
        </Col>
        <Col xs="4" sm="5" md="8" lg="9">
          {!v.isBlank(form.floorPlanHighlightColor) && (
            <ColorPicker
              initialColor={form.floorPlanHighlightColor}
              onChange={(color) => onChangeColor('floorPlanHighlightColor', color)}
            />
          )}
        </Col>
      </Row>

      {isAdmin() && (
        <div>
          <Row form className="mb-4">
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="useStartingFromPrice"
                    id="useStartingFromPrice"
                    value={!parseBoolean(form.useStartingFromPrice)}
                    checked={parseBoolean(form.useStartingFromPrice)}
                    onChange={onTextChange}
                  />
                  {i18n.t('useStartingFromPrice', i18nOpts)}
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-4">
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="enableHomesFromPriceForCommunities"
                    id="enableHomesFromPriceForCommunities"
                    value={!parseBoolean(form.enableHomesFromPriceForCommunities)}
                    checked={parseBoolean(form.enableHomesFromPriceForCommunities)}
                    onChange={onTextChange}
                  />
                  {i18n.t('enableHomesFromPriceForCommunities', i18nOpts)}
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-3">
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="enableValidUntil"
                    id="enableValidUntil"
                    value={!parseBoolean(form.enableValidUntil)}
                    checked={parseBoolean(form.enableValidUntil)}
                    onChange={onTextChange}
                  />
                  {i18n.t('enableValidUntil', i18nOpts)}
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-3">
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="overrideQuickPossessionSize"
                    id="overrideQuickPossessionSize"
                    value={!parseBoolean(form.overrideQuickPossessionSize)}
                    checked={parseBoolean(form.overrideQuickPossessionSize)}
                    onChange={onTextChange}
                  />
                  {i18n.t('overrideQuickPossessionSize', i18nOpts)}
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Collapse isOpen={parseBoolean(form?.enableValidUntil)}>
            <Row form className="mb-3">
              <Col lg="6" md="8" sm="8" xs="12">
                <Label for="name">{i18n.t('validUntil', i18nOpts)}</Label>
                <div className="d-flex m-0 h-auto">
                  <FormGroup className="my-0 mr-2 w-25">
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      placeholder="3"
                      min={0}
                      step={1}
                      value={validUntil?.amount || ''}
                      onChange={onValidUntilChange}
                    />
                  </FormGroup>

                  <FormGroup className="w-75 my-0">
                    <Input
                      type="select"
                      name="period"
                      value={validUntil?.period || ''}
                      onChange={onValidUntilChange}
                    >
                      <option value="">{i18n.t('select.select')}</option>
                      {
                        ValidUntilPeriods.map((c) => (
                          <option key={`valid-until-period-${c.value}`} value={c.value}>
                            {c.label}
                          </option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </div>
                <small className="form-text text-muted">{i18n.t('validUntilDefault', i18nOpts)}</small>
              </Col>
            </Row>
          </Collapse>

          <Row form>
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup>
                <Label for="singleFamilyPriceNote">{i18n.t('singleFamilyPriceNoteLabel', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="singleFamilyPriceNote"
                  id="singleFamilyPriceNote"
                  value={form.singleFamilyPriceNote || ''}
                  onChange={onTextChange}
                  rows={5}
                />
                <small className="form-text text-muted">
                  {i18n.t('singleFamilyPriceNoteHelpText', i18nOpts)}
                </small>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup>
                <Label for="multiFamilyPriceNote">{i18n.t('multiFamilyPriceNoteLabel', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="multiFamilyPriceNote"
                  id="multiFamilyPriceNote"
                  value={form.multiFamilyPriceNote || ''}
                  onChange={onTextChange}
                  rows={5}
                />
                <small className="form-text text-muted">
                  {i18n.t('multiFamilyPriceNoteHelpText', i18nOpts)}
                </small>
              </FormGroup>
            </Col>
          </Row>

          {!isPriceNegotiationEnabled && (
            <Row form>
              <Col lg="6" md="8" sm="8" xs="12">
                <FormGroup>
                  <Label for="fixedDepositAmount">{i18n.t('fixedDepositAmount', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="fixedDepositAmount"
                    id="fixedDepositAmount"
                    value={form.fixedDepositAmount || ''}
                    onChange={onTextChange}
                    rows={5}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row form>
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup>
                <Label for="termsUrl">{i18n.t('termsUrl', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="termsUrl"
                  id="termsUrl"
                  value={form.termsUrl || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'termsUrl')}
                />
                <InputError name="termsUrl" error={error} />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}

    </div>
  );
};

General.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func,
  onChangeColor: PropTypes.func,
  currentCompany: PropTypes.objectOf(PropTypes.any).isRequired
};

General.defaultProps = {
  onTextChange: () => {},
  onChangeColor: () => {},
  error: null,
};

export default General;
