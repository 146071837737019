import i18n from 'i18n-js';

import { DepositStatuses, OfferSignStatuses, OfferStatuses } from '../constants';

const i18nOpts = { scope: 'utils.reserveNowUtils' };

export function getOfferJourneyLabel(offer) {
  const status = offer?.status;
  if (status === OfferStatuses.SUBMITTED) return i18n.t('offerJourneyLabels.submitted', i18nOpts);
  if (status === OfferStatuses.ACCEPTED) return i18n.t('offerJourneyLabels.accepted', i18nOpts);
  if (status === OfferStatuses.COUNTERED) return i18n.t('offerJourneyLabels.countered', i18nOpts);
  if (status === OfferStatuses.RECOUNTERED) return i18n.t('offerJourneyLabels.recountered', i18nOpts);
  if (status === OfferStatuses.REJECTED) return i18n.t('offerJourneyLabels.rejected', i18nOpts);
  if (status === OfferStatuses.DECLINED) return i18n.t('offerJourneyLabels.declined', i18nOpts);
  return i18n.t('offerJourneyLabels.pending', i18nOpts);
}

export function getDepositJourneyLabel(deposit) {
  const status = deposit?.status;
  if (status === DepositStatuses.SUBMITTED) return i18n.t('depositJourneyLabels.submitted', i18nOpts);
  if (status === DepositStatuses.SUCCEEDED) return i18n.t('depositJourneyLabels.submitted', i18nOpts);
  if (status === DepositStatuses.ACCEPTED) return i18n.t('depositJourneyLabels.accepted', i18nOpts);
  if (status === DepositStatuses.REJECTED) return i18n.t('depositJourneyLabels.rejected', i18nOpts);
  return i18n.t('depositJourneyLabels.pending', i18nOpts);
}

export function getSignJourneyLabel(status) {
  if (status === OfferSignStatuses.SUBMITTED) return i18n.t('signJourneyLabels.submitted', i18nOpts);
  if (status === OfferSignStatuses.ACCEPTED) return i18n.t('signJourneyLabels.accepted', i18nOpts);
  if (status === OfferSignStatuses.REJECTED) return i18n.t('signJourneyLabels.rejected', i18nOpts);
  return i18n.t('signJourneyLabels.pending', i18nOpts);
}
