import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import i18n from 'i18n-js';

import { Tabs } from '../utils';
import useHomeRental from '../../../../hooks/useHomeRental';

const i18nOpts = { scope: 'components.admin.workflows.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => {
  const { isRental } = useHomeRental();

  return (
    <Nav vertical className="nav-tabs border-0">
      {isRental ? (
        <>
          <NavItem>
            <NavLink
              className={classNames('py-2 border-0')}
              onClick={() => onChange(Tabs.RENTAL_RESERVE_WORKFLOW)}
              active={activeTab === Tabs.RENTAL_RESERVE_WORKFLOW}
            >
              {i18n.t('rentalReserveWorkflow', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames('py-2 border-0')}
              onClick={() => onChange(Tabs.RENTAL_VERIFIED_WORKFLOW)}
              active={activeTab === Tabs.RENTAL_VERIFIED_WORKFLOW}
            >
              {i18n.t('rentalVerifiedWorkflow', i18nOpts)}
            </NavLink>
          </NavItem>
        </>
      ) : (
        <>
          <NavItem>
            <NavLink
              className={classNames('py-2 border-0')}
              onClick={() => onChange(Tabs.BUYER_RESERVE_WORKFLOW)}
              active={activeTab === Tabs.BUYER_RESERVE_WORKFLOW}
            >
              {i18n.t('buyerReserveWorkflow', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames('py-2 border-0')}
              onClick={() => onChange(Tabs.BUYER_VERIFIED_WORKFLOW)}
              active={activeTab === Tabs.BUYER_VERIFIED_WORKFLOW}
            >
              {i18n.t('buyerVerifiedWorkflow', i18nOpts)}
            </NavLink>
          </NavItem>
        </>
      )}
    </Nav>
  );
};

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
