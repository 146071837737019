import v from 'voca';

import { JOURNEY_REALTOR_QUESTION_STEP } from '../../../../../utils/companyUtils';
import { WorkflowTypes, ReserveStepTypes } from '../../../../../constants';

export function downloadConfigFile(workflowType, downloadableFileUrl) {
  const filename = getWorkflowFilename(workflowType, false);

  const element = document.createElement('a');
  element.href = downloadableFileUrl;
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}

function sanitizeBuyerReserveWorkflowToDownload(workflow) {
  return workflow.map((workflowStep) => {
    const step = { ...workflowStep, type: v.camelCase(workflowStep.type) };
    if (workflowStep.type === ReserveStepTypes.OFFER) {
      let verifications = workflowStep.verifications || [];
      verifications = verifications.map((verification) => ({
        ...verification,
        type: v.camelCase(verification.type)
      }));
      step.verifications = verifications;
      delete step.autocomplete;
      delete step.enabled;
    } else {
      delete step.verifications;
      if ([ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(workflowStep.type)) {
        delete step.enabled;
      } else if (workflowStep.type === JOURNEY_REALTOR_QUESTION_STEP) {
        delete step.label;
        delete step.autocomplete;
      }
    }
    return step;
  });
}

function sanitizeRentalReserveWorkflowToDownload(workflow) {
  return workflow.map((workflowStep) => {
    const step = { ...workflowStep, type: v.camelCase(workflowStep.type) };
    if (workflowStep.type === ReserveStepTypes.OFFER) {
      let verifications = workflowStep.verifications || [];
      verifications = verifications.map((verification) => ({
        ...verification,
        type: v.camelCase(verification.type)
      }));
      step.verifications = verifications;
      delete step.autocomplete;
      delete step.enabled;
    } else {
      delete step.verifications;
      if ([ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(workflowStep.type)) {
        delete step.enabled;
      }
    }
    return step;
  });
}

export function sanitizeWorkflowToDownload(workflowType, workflow) {
  switch (workflowType) {
    case WorkflowTypes.BUYER_RESERVE:
      return sanitizeBuyerReserveWorkflowToDownload(workflow);
    case WorkflowTypes.BUYER_VERIFIED:
      return workflow;
    case WorkflowTypes.RENTAL_RESERVE:
      return sanitizeRentalReserveWorkflowToDownload(workflow);
    case WorkflowTypes.RENTAL_VERIFIED:
      return workflow;
    default:
      return '';
  }
}

export function getWorkflowFilename(workflowType, isSample) {
  const fileSuffix = isSample ? '-sample' : '';

  switch (workflowType) {
    case WorkflowTypes.BUYER_RESERVE:
      return `buyer-reserve-workflow${fileSuffix}.json`;
    case WorkflowTypes.BUYER_VERIFIED:
      return `buyer-verified-workflow${fileSuffix}.json`;
    case WorkflowTypes.RENTAL_RESERVE:
      return `rental-reserve-workflow${fileSuffix}.json`;
    case WorkflowTypes.RENTAL_VERIFIED:
      return `rental-verified-workflow${fileSuffix}.json`;
    default:
      return '';
  }
}
