import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { DEFAULT_RENTAL_VERIFIED_WORKFLOW, WorkflowTypes } from '../../../../constants';
import ConfigFile from '../components/ConfigFile';
import RentalStep from './RentalStep';
import { getCompanyWorkflow } from '../../../../store/actions/companyWorkflowActions';

const i18nOpts = { scope: 'components.admin.workflows.rentalVerifiedWorkflow.index' };

const RentalVerifiedWorkflow = ({
  companyWorkflow, currentCompany, ...props
}) => {
  const [loading, setLoading] = useState(true);
  const workflow = companyWorkflow?.workflow || DEFAULT_RENTAL_VERIFIED_WORKFLOW;

  const loadWorkflow = () => {
    const variables = {
      companyId: currentCompany.id,
      type: WorkflowTypes.RENTAL_VERIFIED
    };
    props.getCompanyWorkflow(variables)
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadWorkflow();
  }, []);

  if (loading) return null;

  return (
    <div>
      <h4 className="mb-3">{i18n.t('title', i18nOpts)}</h4>

      <div className="mb-5">
        {
          workflow.map((step) => (
            <RentalStep key={`rental-step-${step.type}`} step={step} />
          ))
        }
      </div>

      <ConfigFile workflowType={WorkflowTypes.RENTAL_VERIFIED} workflow={workflow} />
    </div>
  );
};

RentalVerifiedWorkflow.propTypes = {};

RentalVerifiedWorkflow.defaultProps = {};

export default connect((store) => ({
  companyWorkflow: store.companyWorkflows.companyWorkflow,
  currentCompany: store.companies.currentCompany
}), {
  getCompanyWorkflow
})(RentalVerifiedWorkflow);
