import v from 'voca';
import i18n from 'i18n-js';

import { isValidAmount } from './currencyUtils';
import { DepositStatuses, OfferSignStatuses, OfferStatuses, ReserveStepTypes } from '../constants';

export function getUserAddress(offer) {
  const { user: { contact } } = offer;
  const address = [contact.street, contact.zip, contact.state].filter((a) => !v.isBlank(a));
  return address.join(', ');
}

export function isNotEnoughFinancing(home, offer) {
  const { totalPrice } = home;
  const hasOfferPrice = isValidAmount(offer?.offerPrice);
  const hasHomePrice = isValidAmount(totalPrice);

  if (hasOfferPrice) {
    if (hasHomePrice) return offer.offerPrice < totalPrice;
    return false;
  } return false;
}

export function isOfferAccepted(offer) {
  return offer?.status === OfferStatuses.ACCEPTED;
}

export function getIsUsingRealtorText(isUsingRealtor) {
  if (isUsingRealtor === true) return i18n.t('buttons.yes');
  if (isUsingRealtor === false) return i18n.t('buttons.no');
  return '';
}

export function isReserveNowStepCompleted(reserveWorkflowStepType, home) {
  switch (reserveWorkflowStepType) {
    case ReserveStepTypes.OFFER:
      return home?.offer?.status === OfferStatuses.ACCEPTED;
    case ReserveStepTypes.SIGN:
      return home?.offer?.signStatus === OfferSignStatuses.ACCEPTED;
    case ReserveStepTypes.DEPOSIT:
      return home?.deposit?.status === DepositStatuses.ACCEPTED;
    default:
      return false;
  }
}
