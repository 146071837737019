import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import { getDeposits } from '../../../../store/actions/depositActions';
import ListEmpty from '../../../common/ListEmpty';
import DepositsList from './DepositList';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.deposits.list.index' };

class List extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };
  }

  componentDidMount() {
    this.loadDeposits();
  }

  loadDeposits() {
    const { props } = this;
    const { currentCompany } = this.props;

    const variables = { filter: { companyId: currentCompany.id } };
    props.getDeposits(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const { deposits, loading } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="mb-4">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={deposits} />

            { deposits.length > 0 && (
              <DepositsList deposits={deposits} showUserInfo />
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  deposits: store.deposits.deposits,
  loading: store.deposits.getDeposits.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  getDeposits
})(List);
