import React from 'react';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';

const i18nOpts = { scope: 'components.admin.workflows.components.multipleReservationsStep.index' };

const MultipleReservationsStep = () => (
  <Card body className="mb-3">
    <div className="d-flex align-items-center justify-content-end">
      <span className="mr-3 font-weight-600">{i18n.t('title', i18nOpts)}</span>
    </div>
  </Card>
);

MultipleReservationsStep.propTypes = {};

MultipleReservationsStep.defaultProps = {};

export default MultipleReservationsStep;
