import React, { useRef, useState } from 'react';
import {
  Button, Input, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { MdDownload, MdUpload } from 'react-icons/md';
import classNames from 'classnames';
import v from 'voca';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getError } from '../../../../../utils/requestUtils';
import { saveCompanyWorkflow } from '../../../../../store/actions/companyWorkflowActions';
import { downloadConfigFile, getWorkflowFilename, sanitizeWorkflowToDownload } from './utils';
import { WorkflowTypes } from '../../../../../constants';

const i18nOpts = { scope: 'components.admin.workflows.components.configFile.index' };

function getWorkflowError(e) {
  const error = getError(e);
  if (v.isString(error)) return error;

  if (!error) return null;
  if (typeof error !== 'object') return null;
  const errors = error.workflow || [];
  const workflowError = errors[0];
  return typeof workflowError === 'string' ? workflowError : null;
}

const ConfigFile = ({
  workflowType, workflow, currentCompany, ...props
}) => {
  const [saving, setSaving] = useState(false);
  const formRef = useRef(null);

  const onUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setSaving(true);

    const variables = { companyId: currentCompany.id, type: workflowType, file };
    props.saveCompanyWorkflow(variables)
      .then(() => {
        toast.success(i18n.t('messages.saved', i18nOpts));
      })
      .catch((e) => {
        const error = getWorkflowError(e);
        if (v.isString(error)) toast.error(error);
        else toast.error(i18n.t('errors.notSaved', i18nOpts));
      })
      .finally(() => {
        clearFileInput();
        setSaving(false);
      });
  };

  const onDownload = () => {
    const downloadableFileUrl = generateDownloadableFileUrl();
    downloadConfigFile(workflowType, downloadableFileUrl);
  };

  const generateDownloadableFileUrl = () => {
    const sanitizedWorkflow = sanitizeWorkflowToDownload(workflowType, workflow);

    const file = new Blob([JSON.stringify(sanitizedWorkflow, null, 2)], { type: 'application/json' });
    return URL.createObjectURL(file);
  };

  const clearFileInput = () => {
    formRef.current.reset();
  };

  const workflowSampleFilename = getWorkflowFilename(workflowType, true);

  return (
    <div>
      <h4 className="mb-3">{i18n.t('title', i18nOpts)}</h4>

      <div className="d-flex align-items-center flex-wrap gap-3">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={saving ? '' : 'file'}
          className={classNames('btn btn-primary btn-sm mb-0', { disabled: saving })}
        >
          {saving ? (
            <Spinner size="sm" className="mr-2" />
          ) : (
            <MdUpload size={18} className="mr-2" />
          )}
          {i18n.t('buttons.upload', i18nOpts)}
        </label>

        <Button color="secondary" outline size="sm" onClick={onDownload}>
          <MdDownload size={18} className="mr-2" />
          {i18n.t('buttons.download', i18nOpts)}
        </Button>

        <a href={`/${workflowSampleFilename}`} download={workflowSampleFilename} className="text-decoration-underline ml-auto">
          {i18n.t('buttons.downloadSample', i18nOpts)}
        </a>
      </div>

      <form ref={formRef}>
        <Input
          type="file"
          name="file"
          id="file"
          accept="application/JSON"
          onChange={onUpload}
          className="d-none"
        />
      </form>
    </div>
  );
};

ConfigFile.propTypes = {
  workflowType: PropTypes.oneOf(Object.values(WorkflowTypes)).isRequired,
  workflow: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

ConfigFile.defaultProps = {
  workflow: []
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}), {
  saveCompanyWorkflow
})(ConfigFile);
