import React from 'react';
import i18n from 'i18n-js';
import { Button, Card, Table } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.getRentalVerified.index' };

const GET_RENTAL_VERIFIED_CODE = `
  <button class="ownly-rental-verified">
    Get Verified
  </button>
`;

const OTHER_ATTRIBUTES = Object.freeze([
  {
    attribute: 'data-agent-id', type: 'string', description: 'Sales Agent ID. <br/> For example <code>a8AKEGOq</code>',
  },
  {
    attribute: 'data-agent-email', type: 'string', description: 'Sales Agent Email. <br/>Only if you don\'t define a <code>data-agent-id</code> value.',
  },
  {
    attribute: 'data-agent-name', type: 'string', description: 'Sales Agent Name. <br/>Only if you define a <code>data-agent-email</code> value.',
  }
]);

const GetRentalVerified = () => (
  <div>
    <h2>{i18n.t('getVerified', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('getVerifiedDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-inline-block">
      <pre>
        &lt;button class=&quot;ownly-rental-verified&quot;&gt;
        <br />
        &nbsp;&nbsp;Get Verified
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={GET_RENTAL_VERIFIED_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <p>{i18n.t('otherAttributes', i18nOpts)}</p>
    <div className="table-responsive">
      <Table bordered>
        <thead>
          <tr>
            <th>{i18n.t('list.attribute', i18nOpts)}</th>
            <th>{i18n.t('list.type', i18nOpts)}</th>
            <th>{i18n.t('list.description', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          {
            OTHER_ATTRIBUTES.map((field) => (
              <tr key={field.attribute}>
                <td><code>{field.attribute}</code></td>
                <td><i>{field.type}</i></td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: field.description }} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  </div>
);

GetRentalVerified.propTypes = {};

GetRentalVerified.defaultProps = {};

export default GetRentalVerified;
