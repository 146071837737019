import i18n from 'i18n-js';
import v from 'voca';

import store from '../store';
import { ReserveStepTypes } from '../constants';

const i18nOpts = { scope: 'utils.companyUtils' };

export const JOURNEY_REALTOR_QUESTION_STEP = 'realtorQuestion';
export const JOURNEY_MULTIPLE_RESERVATIONS_STEP = 'multipleReservations';

export const DEFAULT_BUYER_RESERVE_WORKFLOW = Object.freeze([
  { type: ReserveStepTypes.OFFER, label: i18n.t('buyerReserveSteps.offer', i18nOpts) },
  { type: ReserveStepTypes.SIGN, label: i18n.t('buyerReserveSteps.sign', i18nOpts), autocomplete: true },
  { type: ReserveStepTypes.DEPOSIT, label: i18n.t('buyerReserveSteps.deposit', i18nOpts), autocomplete: true },
  { type: JOURNEY_REALTOR_QUESTION_STEP, enabled: true },
  { type: JOURNEY_MULTIPLE_RESERVATIONS_STEP }
]);

export const DEFAULT_RENTAL_RESERVE_WORKFLOW = Object.freeze([
  { type: ReserveStepTypes.OFFER, label: i18n.t('rentalReserveSteps.offer', i18nOpts) },
  { type: ReserveStepTypes.SIGN, label: i18n.t('rentalReserveSteps.sign', i18nOpts), autocomplete: true },
  { type: ReserveStepTypes.DEPOSIT, label: i18n.t('rentalReserveSteps.deposit', i18nOpts), autocomplete: true },
  { type: JOURNEY_MULTIPLE_RESERVATIONS_STEP }
]);

export function hasCompanyDomain(company) {
  return company?.domains?.length > 0;
}

export function isPriceNegotiationEnabled() {
  const { currentCompany } = store.getState().companies;

  return currentCompany?.buyNowSettings?.enabledPriceNegotiation;
}

export function isSignStepEnabled(steps) {
  const signStepIndex = steps.findIndex((step) => step.type === ReserveStepTypes.SIGN);
  return {
    enabledSignStep: signStepIndex !== -1,
    signStepIndex
  };
}

export function isDepositStepEnabled(steps) {
  const depositStepIndex = steps.findIndex((step) => step.type === ReserveStepTypes.DEPOSIT);
  return {
    enabledDepositStep: depositStepIndex !== -1,
    depositStepIndex
  };
}

export function getClientDomain(company) {
  const companyDomain = company.domains[0];
  return v.isBlank(companyDomain) ? null : new URL(companyDomain).toString();
}

export function isPreApprovalEnabled() {
  const { currentCompany } = store.getState().companies;

  return !!currentCompany?.enablePreApprovalVerification;
}
