import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Spinner, NavItem, NavLink, Nav, TabContent, TabPane
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import v from 'voca';

import { saveCompany } from '../../../store/actions/companyActions';
import DisclaimerTexts from './DisclaimerTexts';
import General from './General';
import { parseBoolean } from '../../../utils/parserUtils';
import Messages from './Messages';
import { getError } from '../../../utils/requestUtils';
import { buildDisclaimerTexts, buildValidUntil, validateValidUntil } from '../../../utils/settingsUtils';
import { stripToNull } from '../../../utils/stringUtils';
import { isMasterAdmin, isTecSupport } from '../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.design.index' };

const DEFAULT_DESIGN = Object.freeze({
  mainColor: '#515050',
  accentColor: '#FFC44B',
  floorPlanHighlightColor: '#40c3e7',
  singleFamilyPriceNote: i18n.t('singleFamilyPriceNoteDefault', i18nOpts),
  multiFamilyPriceNote: i18n.t('multiFamilyPriceNoteDefault', i18nOpts)
});

const Tabs = Object.freeze({
  GENERAL: 'general',
  DISCLAIMER_TEXTS: 'disclaimerTexts',
  MESSAGES: 'messages'
});

function onCancel() {
  navigate('/admin/companies');
}

class Design extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      error: null,
      validUntil: {},
      hasChanges: false,
      saving: false,
      activeTab: Tabs.GENERAL
    };

    this.onChangeColor = this.onChangeColor.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.onDisclaimerTextChange = this.onDisclaimerTextChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onValidUntilChange = this.onValidUntilChange.bind(this);
  }

  componentDidMount() {
    this.populateForm();
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  onChangeColor(name, color) {
    const { form } = this.state;

    form[name] = color.hex;
    this.setState({ form, hasChanges: true });
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form, hasChanges: true });
  }

  onValidUntilChange(event) {
    const { validUntil } = this.state;
    const { name, value } = event.target;

    validUntil[name] = value;
    this.setState({ validUntil, hasChanges: true });
  }

  onDisclaimerTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form.disclaimerTexts = form.disclaimerTexts.map((disclaimerText) => {
      if (disclaimerText.section === name) return { ...disclaimerText, text: value };
      return disclaimerText;
    });
    this.setState({ form, hasChanges: true });
  }

  onMessageChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form.messages = form.messages.map((message) => {
      if (message.section === name) return { ...message, text: value };
      return message;
    });
    this.setState({ form, hasChanges: true });
  }

  onSave() {
    const { props } = this;
    const { currentCompany } = this.props;
    const { form, validUntil } = this.state;

    const input = {
      id: currentCompany.id,
      settings: {
        ...currentCompany.settings,
        design: {
          mainColor: form.mainColor,
          accentColor: form.accentColor,
          floorPlanHighlightColor: form.floorPlanHighlightColor,
          singleFamilyPriceNote: form.singleFamilyPriceNote,
          multiFamilyPriceNote: form.multiFamilyPriceNote,
          fixedDepositAmount: form.fixedDepositAmount
        },
        disclaimerTexts: buildDisclaimerTexts(form.disclaimerTexts),
        messages: form.messages
      },
      useStartingFromPrice: parseBoolean(form.useStartingFromPrice),
      enableHomesFromPriceForCommunities: parseBoolean(form.enableHomesFromPriceForCommunities),
      enableValidUntil: parseBoolean(form?.enableValidUntil),
      overrideQuickPossessionSize: parseBoolean(form?.overrideQuickPossessionSize),
      termsUrl: stripToNull(form.termsUrl),
    };

    if (input?.enableValidUntil) {
      if (validateValidUntil(validUntil)) {
        input.settings.design = {
          ...input.settings.design,
          validUntil: buildValidUntil(validUntil)
        };
      } else {
        toast.error(i18n.t('messages.validUntilError', i18nOpts));
        return;
      }
    }

    const variables = { input };

    this.setState({ saving: true });
    props.saveCompany(variables)
      .then(() => {
        this.populateForm();
        toast.info(i18n.t('messages.saved', i18nOpts));
        this.setState({ error: null });
      })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  populateForm() {
    const { currentCompany } = this.props;
    const settings = currentCompany?.settings || {};
    const design = settings.design || DEFAULT_DESIGN;
    const {
      mainColor, accentColor, singleFamilyPriceNote, multiFamilyPriceNote,
      floorPlanHighlightColor: designFloorPlanColor
    } = design;

    const validUntil = design?.validUntil || {};
    this.setState({ validUntil });

    const floorPlanHighlightColor = designFloorPlanColor || DEFAULT_DESIGN.floorPlanHighlightColor;

    const form = {
      mainColor,
      accentColor,
      floorPlanHighlightColor,
      disclaimerTexts: settings.disclaimerTexts,
      messages: settings.messages,
      startingPrice: currentCompany.startingPrice,
      singleFamilyPriceNote,
      multiFamilyPriceNote,
      useStartingFromPrice: currentCompany.useStartingFromPrice,
      enableHomesFromPriceForCommunities: currentCompany.enableHomesFromPriceForCommunities,
      enableValidUntil: currentCompany.enableValidUntil,
      termsUrl: currentCompany.termsUrl,
      fixedDepositAmount: design?.fixedDepositAmount || null,
      overrideQuickPossessionSize: currentCompany.overrideQuickPossessionSize
    };

    this.setState({ form });
  }

  render() {
    const {
      hasChanges, saving, form, activeTab, validUntil, error
    } = this.state;
    const { currentCompany } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <h2 className="mb-4">{i18n.t('title', i18nOpts)}</h2>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.GENERAL })}
              onClick={() => this.onChangeTab(Tabs.GENERAL)}
            >
              {i18n.t('tabs.general', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.MESSAGES })}
              onClick={() => this.onChangeTab(Tabs.MESSAGES)}
            >
              {i18n.t('tabs.messages', i18nOpts)}
            </NavLink>
          </NavItem>
          {(isMasterAdmin() || isTecSupport()) && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.DISCLAIMER_TEXTS })}
                onClick={() => this.onChangeTab(Tabs.DISCLAIMER_TEXTS)}
              >
                {i18n.t('tabs.disclaimerTexts', i18nOpts)}
              </NavLink>
            </NavItem>
          ) }
        </Nav>

        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.GENERAL}>
            <General
              form={form}
              error={error}
              validUntil={validUntil}
              onTextChange={this.onTextChange}
              onChangeColor={this.onChangeColor}
              onValidUntilChange={this.onValidUntilChange}
              currentCompany={currentCompany}
            />
          </TabPane>
          <TabPane tabId={Tabs.MESSAGES}>
            <Messages form={form} onTextChange={this.onMessageChange} />
          </TabPane>
          {(isMasterAdmin() || isTecSupport()) && (
            <TabPane tabId={Tabs.DISCLAIMER_TEXTS}>
              <DisclaimerTexts form={form} onTextChange={this.onDisclaimerTextChange} />
            </TabPane>
          )}
        </TabContent>

        <Button color="primary" className="mr-3" disabled={saving || !hasChanges} onClick={this.onSave}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>

        {hasChanges && (
          <Button outline color="secondary" onClick={onCancel} className="mr-3">
            {i18n.t('buttons.cancel')}
          </Button>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(Design);
