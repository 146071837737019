import React from 'react';

const HomeEvaluationIcon = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" color="#475467" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.63831 0.602961C4.13561 1.86048 2.80144 4.06764 2.80144 6.95103C2.80144 11.0018 5.48317 13.8049 9.38048 13.828C10.9809 13.8376 11.78 13.6509 12.9711 12.8776C16.5035 10.5833 16.9533 5.61745 14.2833 2.28424C12.5389 0.106124 9.12249 -0.64497 6.63831 0.602961ZM11.2492 4.30273L12.8031 5.69771L12.7199 7.67188L12.6366 9.64634L11.5676 9.73918C10.5479 9.73308 10.4986 9.81345 10.4986 8.77666C10.4986 8.05671 10.3312 7.62665 9.97259 7.4251C8.83743 7.03777 8.21793 7.80754 8.21793 8.93358C8.21793 9.76913 8.21793 9.73918 7.29143 9.74008L6.36492 9.64634L6.28168 7.67757L6.19872 5.70879L7.62354 4.30842C8.40751 3.53816 9.19404 2.90805 9.37193 2.90805C9.54953 2.90805 10.3942 3.53576 11.2492 4.30273ZM1.35125 13.9307C0.0552807 15.1825 -0.113771 15.4751 0.0538552 16.1768C0.364876 17.4789 1.36151 17.2325 2.93543 15.4646L4.28784 13.946L3.63758 13.2185C3.2798 12.8187 2.95424 12.4914 2.91405 12.4914C2.87414 12.4914 2.17056 13.1392 1.35125 13.9307Z" fill="currentColor" />
  </svg>
);

HomeEvaluationIcon.propTypes = {};

HomeEvaluationIcon.defaultProps = {};

export default HomeEvaluationIcon;
