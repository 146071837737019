import React from 'react';
import _ from 'lodash';

const OfferIcon = () => {
  const iconId = _.uniqueId('offer-icon');

  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" color="black" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#clip0_756_5885-${iconId})`}>
        <path d="M30.1116 13.727C29.3581 13.0933 28.2342 13.1859 27.5956 13.9384L23.5082 18.1313H23.5124C23.2913 17.373 22.5894 16.819 21.7586 16.819H14.989C14.1106 16.4357 13.1394 16.2227 12.1188 16.2227C9.2909 16.2227 6.84546 17.5909 5.67773 20.1835L11.0814 25.5491L11.0914 25.5411L12.0219 24.6453C12.4672 24.216 13.0624 23.9762 13.6814 23.9762H21.6458C22.8859 23.9762 24.0773 23.4934 24.9662 22.63L30.3234 16.2378C30.9594 15.4867 30.8648 14.3625 30.1116 13.727Z" fill="white" />
        <path d="M24.5496 6.56047C24.5496 9.85454 21.8741 12.5248 18.5736 12.5248C15.2731 12.5248 12.5977 9.85454 12.5977 6.56047C12.5977 3.26664 15.2731 0.596191 18.5736 0.596191C21.8741 0.596191 24.5496 3.26664 24.5496 6.56047Z" fill="currentColor" />
        <path d="M10.9673 27.1037L8.07312 29.9493L1.36328 23.2013L4.20614 20.3557C4.67267 19.89 5.42991 19.8898 5.89667 20.3557L10.9673 25.4164C11.4369 25.8851 11.4313 26.6406 10.9673 27.1037Z" fill="currentColor" />
        <path d="M14.8445 20.7177C15.0778 20.4845 15.0778 20.1069 14.8445 19.8737C14.611 19.6411 14.2327 19.6411 13.999 19.8737C13.766 20.1069 13.766 20.4845 13.999 20.7177C14.2327 20.9503 14.611 20.9503 14.8445 20.7177Z" fill="currentColor" />
        <path d="M12.0156 6.56064C12.0156 10.1782 14.9645 13.1215 18.5891 13.1215C22.2137 13.1215 25.1626 10.1782 25.1626 6.56064C25.1626 2.9431 22.2137 0 18.5891 0C14.9645 0 12.0156 2.9431 12.0156 6.56064ZM23.9675 6.56064C23.9675 9.52052 21.5548 11.9286 18.5891 11.9286C15.6234 11.9286 13.2107 9.52052 13.2107 6.56064C13.2107 3.60099 15.6234 1.19295 18.5891 1.19295C21.5548 1.19295 23.9675 3.60099 23.9675 6.56064Z" fill="currentColor" />
        <path d="M7.66257 30.3693C7.8942 30.6023 8.2713 30.6049 8.50596 30.3742L11.4032 27.5254C11.8954 27.0342 12.0451 26.3069 11.8287 25.6746L12.4519 25.0748C12.7874 24.7513 13.2292 24.573 13.6962 24.573H21.6405C23.0499 24.573 24.382 24.0328 25.3919 23.0519C25.4335 23.0116 25.0765 23.4313 30.8117 16.5901C31.6623 15.5857 31.5351 14.0774 30.5278 13.2275C29.5267 12.3855 28.0272 12.5065 27.1742 13.4937L23.6479 17.111C23.2026 16.564 22.5236 16.2231 21.79 16.2231H15.1261C14.1779 15.8271 13.1717 15.6267 12.1336 15.6267C9.25596 15.6267 6.74328 16.9532 5.39482 19.4402C4.82672 19.3328 4.22756 19.5043 3.79652 19.9345L0.953663 22.7802C0.721565 23.0126 0.721098 23.3885 0.952729 23.6215L7.66257 30.3693ZM12.1334 16.8194C13.0487 16.8194 13.9337 17.0033 14.7642 17.3659C14.8399 17.399 14.9214 17.416 15.0038 17.416H21.79C22.4379 17.416 22.9853 17.9434 22.9853 18.6087C22.9853 19.2666 22.4491 19.8017 21.79 19.8017H16.9143C16.5843 19.8017 16.3168 20.0687 16.3168 20.398C16.3168 20.7276 16.5843 20.9944 16.9143 20.9944H21.79C23.1081 20.9944 24.1803 19.9243 24.1803 18.6087C24.1803 18.5039 24.1728 18.3999 24.1593 18.2969C27.5705 14.7973 28.0515 14.3056 28.072 14.2811C28.4979 13.7794 29.254 13.7157 29.757 14.139C30.2604 14.5638 30.3241 15.3179 29.8971 15.8225L24.5371 22.2176C23.7544 22.9676 22.7268 23.3803 21.6405 23.3803H13.6962C12.9177 23.3803 12.1808 23.6774 11.6218 24.2165L11.1139 24.7056L6.43226 20.0333C7.52644 17.9846 9.57959 16.8194 12.1334 16.8194ZM4.64225 20.7774C4.83932 20.5807 5.14194 20.5453 5.38221 20.6935C5.48565 20.7565 5.18864 20.4866 10.5582 25.8382C10.7971 26.0766 10.7842 26.4565 10.5615 26.6788L8.0915 29.1073L2.2197 23.2022L4.64225 20.7774Z" fill="currentColor" />
        <path d="M17.8565 3.00062V3.68111C17.1609 3.9272 16.6612 4.59021 16.6612 5.36788C16.6612 6.35086 17.4595 7.15136 18.4433 7.15695C18.7473 7.15882 19.0137 7.383 19.0478 7.68456C19.0884 8.04415 18.8064 8.3499 18.454 8.3499C18.2018 8.3499 17.9298 8.19353 17.687 7.90945C17.4967 7.68643 17.17 7.61768 16.9181 7.76776C16.5923 7.96188 16.5297 8.38905 16.7632 8.66637C17.0827 9.04554 17.4584 9.30981 17.8565 9.44311V10.1206C17.8565 10.4193 18.069 10.6866 18.3653 10.7293C18.7347 10.7822 19.0516 10.4976 19.0516 10.1392V9.44008C19.7471 9.19398 20.2468 8.53098 20.2468 7.75331C20.2468 6.77033 19.4485 5.97006 18.4648 5.96424C18.1607 5.96237 17.8943 5.73818 17.8602 5.43663C17.8196 5.07704 18.1017 4.77129 18.454 4.77129C18.6609 4.77129 18.8792 4.87429 19.0861 5.06958C19.3007 5.27163 19.6313 5.31195 19.8683 5.13647C20.1738 4.91065 20.1929 4.47812 19.9316 4.22573C19.6283 3.9328 19.3247 3.7671 19.0516 3.67668V2.98221C19.0516 2.62356 18.7347 2.33901 18.3653 2.39215C18.069 2.43456 17.8565 2.70186 17.8565 3.00062Z" fill="white" />
      </g>
      <defs>
        <clipPath id={`clip0_756_5885-${iconId}`}>
          <rect width="30.5455" height="30.5455" fill="white" transform="translate(0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
};

OfferIcon.propTypes = {};

OfferIcon.defaultProps = {};

export default OfferIcon;
