import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import v from 'voca';

import { formatTimestamp } from '../../../../../utils/dateUtils';
import SortIcons from '../../../../common/SortIcons';
import { SortOrders } from '../../../../../constants';
import setSortFilter from '../../../../../utils/sortUtils';
import api from '../../../../../api';
import { listDepositsQuery } from '../../../../../graphql';

const i18nOpts = { scope: 'components.admin.deposits.list.depositList.index' };
const columnOptions = {
  DATE: 'createdAt'
};
const DEFAULT_SORT = { field: columnOptions.DATE, direction: SortOrders.DESC };

async function fetchAsyncDeposits(variables) {
  return api.graphql(listDepositsQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

const classNames = (status) => {
  switch (status) {
    case 'pending':
    case 'in progress':
    default:
      return 'pending';
    case 'failed':
    case 'cancelled':
      return 'failed';
    case 'successful':
      return 'successful';
  }
};

const List = ({
  customer, deposits, showUserInfo, currentCompany
}) => {
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [depositsList, setDeposits] = useState(deposits);
  const sortList = (field) => {
    setSort(setSortFilter(field, sort));
  };

  useEffect(() => {
    loadDeposits();
  }, [sort.field, sort.direction]);

  const loadDeposits = () => {
    const contactId = customer?.id || null;
    const variables = { filter: { contactId, companyId: currentCompany.id }, contactId };
    if (sort) variables.sort = sort;

    fetchAsyncDeposits(variables)
      .then((items) => setDeposits(items));
  };

  return (
    <div className="table-responsive">
      <Table bordered>
        <thead>
          <tr>
            { showUserInfo && (
            <th>{i18n.t('name', i18nOpts)}</th>
            )}
            <th>{i18n.t('amount', i18nOpts)}</th>
            <th onClick={() => sortList(columnOptions.DATE)} role="button">
              {i18n.t('depositDate', i18nOpts)}
              <SortIcons sort={sort} field={columnOptions.DATE} />
            </th>
            <th>{i18n.t('status', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          { depositsList.map((d) => (
            <tr key={`deposit-${d.id}`} className="deposit">
              { showUserInfo && (
              <td>
                <Link to={`/admin/customers/${d.user.contact.id}`}>
                  {d.user.contact.name}
                </Link>
              </td>
              )}
              <td>{d.amount}</td>
              <td>{formatTimestamp(d.createdAt)}</td>
              <td className="status-cell">
                <div className={`status ${classNames(d.status)}`}>{d.status}</div>
                {!v.isBlank(d.errorMessage) && (
                  <>
                    <br />
                    <div>{d.errorMessage}</div>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(List);
