import React, { useState } from 'react';
import {
  Col, Row, TabContent, TabPane
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import NavTabs from './NavTabs';
import { Tabs } from './utils';
import BuyerReserveWorkflow from './BuyerReserveWorkflow';
import BuyerVerifiedWorkflow from './BuyerVerifiedWorkflow';
import RentalVerifiedWorkflow from './RentalVerifiedWorkflow';
import RentalReserveWorkflow from './RentalReserveWorkflow';
import useHomeRental from '../../../hooks/useHomeRental';

const i18nOpts = { scope: 'components.admin.workflows.index' };

const Workflows = () => {
  const { isRental } = useHomeRental();
  const defaultWorkflow = isRental ? Tabs.RENTAL_RESERVE_WORKFLOW : Tabs.BUYER_RESERVE_WORKFLOW;
  const [activeTab, setActiveTab] = useState(defaultWorkflow);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <h2 className="mb-4">{i18n.t('title', i18nOpts)}</h2>

      <Row>
        <Col xs={12} sm={4} md={3}>
          <NavTabs activeTab={activeTab} onChange={onTabChange} />
        </Col>
        <Col xs={12} sm={8} md={9}>
          <TabContent activeTab={activeTab}>
            {isRental ? (
              <>
                <TabPane tabId={Tabs.RENTAL_RESERVE_WORKFLOW}>
                  {activeTab === Tabs.RENTAL_RESERVE_WORKFLOW && (
                    <RentalReserveWorkflow />
                  )}
                </TabPane>
                <TabPane tabId={Tabs.RENTAL_VERIFIED_WORKFLOW}>
                  {activeTab === Tabs.RENTAL_VERIFIED_WORKFLOW && (
                    <RentalVerifiedWorkflow />
                  )}
                </TabPane>
              </>
            ) : (
              <>
                <TabPane tabId={Tabs.BUYER_RESERVE_WORKFLOW}>
                  {activeTab === Tabs.BUYER_RESERVE_WORKFLOW && (
                    <BuyerReserveWorkflow />
                  )}
                </TabPane>
                <TabPane tabId={Tabs.BUYER_VERIFIED_WORKFLOW}>
                  {activeTab === Tabs.BUYER_VERIFIED_WORKFLOW && (
                    <BuyerVerifiedWorkflow />
                  )}
                </TabPane>
              </>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

Workflows.propTypes = {};

Workflows.defaultProps = {};

export default Workflows;
