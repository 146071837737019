import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import { RiLinkM } from 'react-icons/ri';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import useHomeRental from '../../../../../../hooks/useHomeRental';
import {
  buildReserveNowInvitationUrl, buildVerifiedInvitationUrl,
} from '../../../../../../utils/salesRepUtils';
import {
  compareUnionTypeObjectTypename,
} from '../../../../../../utils/objectUtils';
import { SalesInvitationTypes } from '../../constants';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.components.copyInviteLinkButton.index' };

const CopyInviteLinkButton = ({
  currentUser, invitationType, selectedHome
}) => {
  const { isRental } = useHomeRental();

  const inviteUrl = useMemo(() => {
    if (invitationType === SalesInvitationTypes.RESERVE_NOW) {
      const isUnit = compareUnionTypeObjectTypename(selectedHome, 'Unit');
      return buildReserveNowInvitationUrl(currentUser.hashId, selectedHome.hashId, isUnit);
    }
    return buildVerifiedInvitationUrl(currentUser.hashId, isRental);
  }, [invitationType]);

  const onCopy = () => {
    toast.info(i18n.t('messages.copied', i18nOpts));
  };

  return (
    <CopyToClipboard text={inviteUrl} onCopy={onCopy}>
      <Button outline color="primary" block className="text-nowrap">
        <RiLinkM size="1.2rem" className="mr-2 align-middle" />
        {i18n.t('title', i18nOpts)}
      </Button>
    </CopyToClipboard>
  );
};

CopyInviteLinkButton.propTypes = {};

CopyInviteLinkButton.defaultProps = {};

export default connect((store) => ({
  currentUser: store.users.currentUser,
  invitationType: store.salesInvitation.invitationType,
  selectedHome: store.salesInvitation.invitedHome
}))(CopyInviteLinkButton);
