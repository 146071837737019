import React from 'react';
import { Badge, Card } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import { ReserveStepTypes } from '../../../../../constants';
import OfferIcon from '../../../../../assets/icons/offer/OfferIcon';
import SignIcon from '../../../../../assets/icons/offer/SignIcon';
import DepositIcon from '../../../../../assets/icons/offer/DepositIcon';
import OptionalBadge from '../../components/OptionalBadge';

const i18nOpts = { scope: 'components.admin.workflows.buyerReserveWorkflow.reserveStep.index' };

const ReserveStep = ({ step }) => {
  let icon;
  const { verifications } = step;

  switch (step.type) {
    case ReserveStepTypes.OFFER:
      icon = <OfferIcon />;
      break;

    case ReserveStepTypes.SIGN:
      icon = <SignIcon />;
      break;

    case ReserveStepTypes.DEPOSIT:
      icon = <DepositIcon />;
      break;

    default:
      icon = null;
      break;
  }

  return (
    <Card body className="mb-3">
      <div className="d-flex align-items-center gap-3">
        <div>{icon}</div>
        <div className="d-flex flex-column flex-grow-1">
          <span className="font-weight-600">{step.label}</span>
        </div>
        <div>
          {step.type === ReserveStepTypes.OFFER && (
            <div className="d-table-cell">
              {
                (verifications || []).map((verification) => (
                  <div key={`verification-${verification.type}`} className="d-table-row">
                    <div className="d-table-cell p-1 font-weight-500">{i18n.t(v.camelCase(verification.type), i18nOpts)}</div>
                    <div className="d-table-cell p-1">
                      <OptionalBadge optional={verification.optional} />
                    </div>
                  </div>
                ))
              }
            </div>
          )}

          {[ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(step.type) && (
            <div className="d-table-cell">
              <span className="mr-2 font-weight-500">{i18n.t('autocomplete', i18nOpts)}</span>
              <Badge color={step.autocomplete ? 'primary' : 'light'}>
                {step.autocomplete ? i18n.t('buttons.yes') : i18n.t('buttons.no')}
              </Badge>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

ReserveStep.propTypes = {};

ReserveStep.defaultProps = {};

export default ReserveStep;
