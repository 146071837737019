import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import ReserveStep from './ReserveStep';
import ConfigFile from '../components/ConfigFile';
import { WorkflowTypes } from '../../../../constants';
import { DEFAULT_RENTAL_RESERVE_WORKFLOW, JOURNEY_MULTIPLE_RESERVATIONS_STEP } from '../../../../utils/companyUtils';
import { getCompanyWorkflow } from '../../../../store/actions/companyWorkflowActions';
import MultipleReservationsStep from '../components/MultipleReservationsStep';

const i18nOpts = { scope: 'components.admin.workflows.rentalReserveWorkflow.index' };

const RentalReserveWorkflow = ({ companyWorkflow, currentCompany, ...props }) => {
  const [loading, setLoading] = useState(true);
  const workflow = companyWorkflow?.workflow || DEFAULT_RENTAL_RESERVE_WORKFLOW;

  const loadWorkflow = () => {
    const variables = {
      companyId: currentCompany.id,
      type: WorkflowTypes.RENTAL_RESERVE
    };
    props.getCompanyWorkflow(variables)
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadWorkflow();
  }, []);

  if (loading) return null;

  return (
    <div>
      <h4 className="mb-3">{i18n.t('title', i18nOpts)}</h4>

      <div className="mb-5">
        {
          workflow.map((reserveStep) => {
            if (reserveStep.type === JOURNEY_MULTIPLE_RESERVATIONS_STEP) {
              return (
                <MultipleReservationsStep key={`rental-reserve-step-${reserveStep.type}`} />
              );
            }
            return (<ReserveStep step={reserveStep} key={`rental-reserve-step-${reserveStep.type}`} />);
          })
        }
      </div>

      <ConfigFile workflowType={WorkflowTypes.RENTAL_RESERVE} workflow={workflow} />
    </div>
  );
};

RentalReserveWorkflow.propTypes = {};

RentalReserveWorkflow.defaultProps = {};

export default connect((store) => ({
  companyWorkflow: store.companyWorkflows.companyWorkflow,
  currentCompany: store.companies.currentCompany
}), {
  getCompanyWorkflow
})(RentalReserveWorkflow);
