import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Spinner, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import v from 'voca';

import { saveCompany, setCurrentCompany } from '../../../../store/actions/companyActions';
import GeneralSettings from './GeneralSettings';
import FieldsMapping from './FieldsMapping';
import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import { getError } from '../../../../utils/requestUtils';
import { stripToEmpty } from '../../../../utils/stringUtils';
import LoginToApp from './LoginToApp';
import { SocketContext } from '../../../../providers/Socket';
import { WebSocketMessages } from '../../../../constants';

const i18nOpts = { scope: 'components.admin.integrations.hubSpot.index' };

const Tabs = Object.freeze({
  GENERAL_SETTINGS: 'generalSettings',
  FIELDS_MAPPING: 'fieldsMapping',
});

function onCancel() {
  navigate('/admin/integrations');
}

const HubSpot = ({ currentCompany, ...props }) => {
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.GENERAL_SETTINGS);
  const socket = useContext(SocketContext);
  const hubSpotSettings = currentCompany?.hubSpotSettings;
  const needsLogin = !hubSpotSettings?.hasToken;

  const populateForm = () => {
    const { id } = currentCompany;

    setForm({ id, ...hubSpotSettings });
  };

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const input = {
      id: form.id,
      hubSpotSettings: {
        contactListId: stripToEmpty(form.contactListId) || '',
        lifecycleStage: form.lifecycleStage || '',
        leadStatus: form.leadStatus || '',
        casl: stripToEmpty(form.casl) || '',
        leadSource: stripToEmpty(form.leadSource) || '',
        buildLink: stripToEmpty(form.buildLink) || '',
        community: stripToEmpty(form.community) || '',
        model: stripToEmpty(form.model) || '',
        verifiedStatusField: stripToEmpty(form.verifiedStatusField) || '',
        reservationStepField: stripToEmpty(form.reservationStepField) || '',
      }
    };

    setSaving(true);

    const variables = { input };
    props.saveCompany(variables)
      .then(() => {
        navigate('/admin/integrations');
        props.setCurrentCompany(form.id);
        toast.success(i18n.t('messages.success', i18nOpts));
      })
      .catch((error) => {
        const saveError = getError(error);
        if (v.isString(error)) toast.error(saveError);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const addSocketEventListener = () => {
    socket.on(WebSocketMessages.HUB_SPOT_CLIENT_AUTHORIZED, ({ companyId }) => {
      const parsedCompanyId = parseInt(companyId, 10);
      if (parsedCompanyId === currentCompany.id) {
        toast.success(i18n.t('messages.successfulLogin', i18nOpts));

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    });
  };

  const onChangeTab = (tab) => () => {
    setActiveTab(tab);
  };

  useEffect(() => {
    socket.connect();
    addSocketEventListener();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (currentCompany) populateForm();
  }, [currentCompany]);

  if (!currentCompany) return null;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      {
        needsLogin ? (
          <LoginToApp hubSpotSettings={hubSpotSettings} />
        ) : (
          <Form onSubmit={onSubmit}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === Tabs.GENERAL_SETTINGS ? 'active' : ''}`}
                  onClick={onChangeTab(Tabs.GENERAL_SETTINGS)}
                >
                  {i18n.t('tabs.generalSettings', i18nOpts)}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === Tabs.FIELDS_MAPPING ? 'active' : ''}`}
                  onClick={onChangeTab(Tabs.FIELDS_MAPPING)}
                >
                  {i18n.t('tabs.fieldsMapping', i18nOpts)}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="py-4">
              <TabPane tabId={Tabs.GENERAL_SETTINGS}>
                <GeneralSettings form={form} onTextChange={onTextChange} />
              </TabPane>
              <TabPane tabId={Tabs.FIELDS_MAPPING}>
                <FieldsMapping form={form} onTextChange={onTextChange} />
              </TabPane>
            </TabContent>

            <Button color="primary" className="mr-3" disabled={saving}>
              {saving && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.save')}
            </Button>
            <Button outline color="secondary" onClick={onCancel} disabled={saving} className="mr-3">
              {i18n.t('buttons.cancel')}
            </Button>
          </Form>
        )
      }

    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany,
  setCurrentCompany
})(HubSpot);
