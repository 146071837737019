import v from 'voca';

export function hasFormValues(form) {
  const values = Object.values(form);
  return values.some((fv) => !v.isBlank(fv));
}

/**
 * Check if form values have changed
 * @param initialForm
 * @param form
 * @returns {boolean}
 */
export function hasFormChanges(initialForm, form) {
  return Object.entries(initialForm).toString() !== Object.entries(form).toString();
}

function isNumericString(string) {
  return /^[0-9]+$/.test(string);
}

function splitDashedNumber(phone) {
  const splitPhone = phone.split('-');
  const noDashPhone = splitPhone.filter((item) => isNumericString(item));
  return noDashPhone.length === splitPhone.length
    ? noDashPhone.join('') : '';
}

export function processPhoneInput(phoneInput) {
  if (phoneInput.includes('-')) return splitDashedNumber(phoneInput);
  return isNumericString(phoneInput) ? phoneInput : '';
}

export function isPhoneValid(phoneInput) {
  if (!phoneInput || phoneInput === '') return true;

  return processPhoneInput(phoneInput);
}

export function isUnsupportedImageError(error) {
  if (!error || !error?.message) return false;

  return error?.message.includes('unsupported image');
}

export function isFormValid(form, fields) {
  return !fields.some((field) => v.isBlank(form[field]));
}
