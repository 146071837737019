import React, { useState } from 'react';
import {
  Button, ButtonGroup, Col, FormGroup, Input, Label, Row, Spinner
} from 'reactstrap';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import v from 'voca';
import i18n from 'i18n-js';

import InputError, { isInputInvalid } from '../../../ui/InputError';
import { DownPaymentTypes } from '../../../../constants';
import { parseToFloat, parseToInt } from '../../../../utils/parserUtils';
import { getError } from '../../../../utils/requestUtils';
import { isValidAmount } from '../../../../utils/currencyUtils';
import { hasFormChanges } from '../../../../utils/formUtils';
import { saveCompany } from '../../../../store/actions/companyActions';

const i18nOpts = { scope: 'components.admin.mortgageRate.mortgageRateSettings.index' };

const LOAN_TERMS = [60, 120, 180, 240, 300, 360];

const MortgageRateSettings = ({ currentCompany, ...props }) => {
  const mortgageRateSettings = currentCompany.mortgageRateSettings
    || { downPaymentType: DownPaymentTypes.AMOUNT };
  const [initialForm, setInitialForm] = useState(mortgageRateSettings);
  const [form, setForm] = useState(mortgageRateSettings);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onDownPaymentTypeChange = (downPaymentType) => {
    const event = { target: { name: 'downPaymentType', value: downPaymentType } };
    onTextChange(event);
  };

  const onSave = (event) => {
    event.preventDefault();
    setSaving(true);
    setError(null);

    const input = {
      id: currentCompany.id,
      mortgageRateSettings: {
        ...form,
        downPayment: parseToFloat(form.downPayment) || null,
        loanTerm: parseToInt(form.loanTerm),
        interestRate: parseToFloat(form.interestRate) || null
      }
    };

    props.saveCompany({ input })
      .then(() => {
        toast.success(i18n.t('messages.saved', i18nOpts));
        setInitialForm(form);
      })
      .catch((e) => {
        const formattedError = getError(e);
        if (v.isString(formattedError)) toast.error(formattedError);
        else {
          const validationErrors = formattedError?.mortgageRateSettings || [];
          const settingsError = validationErrors.reduce((a, i) => ({ ...a, ...i }), {});
          setError(settingsError);
        }
      })
      .finally(() => setSaving(false));
  };

  const isValidForm = () => {
    const interestRate = parseToFloat(form.interestRate) || 0;
    return !v.isBlank(form.downPayment) && !v.isBlank(form.loanTerm)
      && isValidAmount(interestRate);
  };

  const hasChanges = hasFormChanges(initialForm, form);

  return (
    <div>
      <Row>
        <Col xs="12" sm="12" md="6" lg="4">
          <FormGroup>
            <Label for="downPayment">{i18n.t('downPayment', i18nOpts)}</Label>
            <div className={classNames('d-flex', { 'is-invalid': isInputInvalid(error, 'downPayment') })}>
              <div className="input-group w-50">
                {form.downPaymentType === DownPaymentTypes.AMOUNT && (
                  <div className="input-group-prepend">
                    <div className="input-group-text">$</div>
                  </div>
                )}
                <Input
                  type="number"
                  name="downPayment"
                  id="downPayment"
                  value={form.downPayment || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'downPayment')}
                />
                {form.downPaymentType === DownPaymentTypes.PERCENT && (
                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                )}
              </div>

              <div className="w-50 pl-4">
                <ButtonGroup>
                  <Button
                    color="dark"
                    outline
                    active={form.downPaymentType === DownPaymentTypes.AMOUNT}
                    onClick={() => onDownPaymentTypeChange(DownPaymentTypes.AMOUNT)}
                  >
                    $
                  </Button>
                  <Button
                    color="dark"
                    outline
                    active={form.downPaymentType === DownPaymentTypes.PERCENT}
                    onClick={() => onDownPaymentTypeChange(DownPaymentTypes.PERCENT)}
                  >
                    %
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <InputError name="downPayment" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="loanTerm">{i18n.t('loanTerm', i18nOpts)}</Label>
            <Input
              type="select"
              name="loanTerm"
              id="loanTerm"
              value={form.loanTerm || ''}
              onChange={onTextChange}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                LOAN_TERMS.reverse().map((lt) => (
                  <option key={`loan-term-${lt}`} value={lt}>
                    {i18n.t('years', { ...i18nOpts, years: Math.round(lt / 12) })}
                  </option>
                ))
              }
            </Input>
            <InputError name="loanTerm" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="interestRate">{i18n.t('interestRate', i18nOpts)}</Label>
            <div className={classNames('input-group w-50', { 'is-invalid': isInputInvalid(error, 'interestRate') })}>
              <Input
                type="number"
                name="interestRate"
                id="interestRate"
                value={form.interestRate || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'interestRate')}
              />
              <div className="input-group-append">
                <div className="input-group-text">%</div>
              </div>
            </div>
            <InputError name="interestRate" error={error} />
          </FormGroup>
        </Col>
      </Row>

      <Button color="primary" disabled={saving || !hasChanges || !isValidForm()} onClick={onSave}>
        {saving && (<Spinner size="sm" className="mr-2" />)}
        {i18n.t('buttons.save')}
      </Button>

      {currentCompany.enableVerifiedBuyingPower && (
        <div className="mt-3 text-secondary">{i18n.t('buyingPowerInfo', i18nOpts)}</div>
      )}
    </div>
  );
};

MortgageRateSettings.propTypes = {};

MortgageRateSettings.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(MortgageRateSettings);
