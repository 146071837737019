import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import i18n from 'i18n-js';

import useHomeRental from '../../../../../../hooks/useHomeRental';
import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.sales.deals.list.listView.index' };

const ListView = ({ deals }) => {
  const { typeI18nKey } = useHomeRental();
  const isVerifiedEnabled = useIsVerifiedEnabled();

  return (
    <Table responsive bordered style={{ height: '1px' }}>
      <thead>
        <tr>
          <th className="fixed-column">{i18n.t('customer', i18nOpts)}</th>
          <th>{i18n.t('dealPrice', i18nOpts)}</th>
          <th>{i18n.t('dealState', i18nOpts)}</th>
          <th>{i18n.t('modelUnit', i18nOpts)}</th>
          <th>{i18n.t('communityProject', i18nOpts)}</th>
          {isVerifiedEnabled && (
            <th>{i18n.t(`${typeI18nKey}.financialAmount`, i18nOpts)}</th>
          )}
          <th>{i18n.t('usingRealtor', i18nOpts)}</th>
          <th>{i18n.t('lastActive', i18nOpts)}</th>
        </tr>
      </thead>
      <tbody>
        {
          deals.map((deal) => (
            <ListItem key={`deal-${deal.contactId}`} deal={deal} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {};

ListView.defaultProps = {};

export default connect((store) => ({
  deals: store.deals.deals
}))(ListView);
