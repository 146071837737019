import React, { useState } from 'react';
import {
  Card, CardBody, CardHeader, Collapse, Input
} from 'reactstrap';
import { MdExpandMore } from 'react-icons/md';
import classnames from 'classnames';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import v from 'voca';
import moment from 'moment';

import './styles.scss';
import { DateFormats } from '../../../../constants';
import { isRecently } from '../../../../utils/dateUtils';

const i18nOpts = { scope: 'components.admin.design.disclaimerTexts.index' };

const SectionCard = ({ disclaimerText, onTextChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card className={classnames('mb-3', { opened: isOpen })}>
      <CardHeader role="button" aria-hidden onClick={onToggle}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 pr-2">
            <span>{i18n.t(`sections.${disclaimerText.section}`, i18nOpts)}</span>
            {disclaimerText?.maxLength && disclaimerText?.maxLength > 0 && (
              <span>
              &nbsp;
                {i18n.t('maxLength', { amount: disclaimerText?.maxLength, ...i18nOpts })}
              </span>
            )}
          </div>
          <div className="expand-icon">
            <MdExpandMore />
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Input
            type="textarea"
            name={disclaimerText.section}
            id={disclaimerText.section}
            value={disclaimerText.text || ''}
            onChange={onTextChange}
            rows={5}
            maxLength={disclaimerText?.maxLength}
          />

          {!v.isBlank(disclaimerText.updatedAt) && (
            <div className="mt-2 text-right">
              <small className={isRecently(disclaimerText.updatedAt) ? 'text-success' : 'text-muted'}>
                {i18n.t('updatedOn', {
                  ...i18nOpts,
                  date: moment(disclaimerText.updatedAt).format(DateFormats.MMM_DD_YYYY)
                })}
              </small>
            </div>
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
};
const DisclaimerTexts = ({ form, onTextChange }) => {
  const disclaimerTexts = form.disclaimerTexts || [];

  return (
    <div id="disclaimer-texts-container" className="mb-4">
      {
        disclaimerTexts.map((disclaimerText) => (
          <SectionCard
            key={disclaimerText.section}
            disclaimerText={disclaimerText}
            onTextChange={onTextChange}
          />
        ))
      }
    </div>
  );
};

DisclaimerTexts.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func
};

DisclaimerTexts.defaultProps = {
  onTextChange: () => {
  }
};

export default DisclaimerTexts;
