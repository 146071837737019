import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import {
  Card, CardBody, CardHeader, Row, Col, Button, Spinner, Label, Input, FormGroup
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import _ from 'lodash';
import v from 'voca';
import { Helmet } from 'react-helmet';

import './styles.scss';
import StageItem from './StageItem';
import { ContactStages } from '../../../constants';
import { getContactStageName } from '../../../utils/enumUtils';
import { saveCompany } from '../../../store/actions/companyActions';
import InputError, { isInputInvalid } from '../../ui/InputError';
import { getError } from '../../../utils/requestUtils';
import { stripToNull } from '../../../utils/stringUtils';

const i18nOpts = { scope: 'components.admin.contactStages.index' };

function getStages(stages) {
  return stages.map((stageKey) => {
    const stage = ContactStages[stageKey];
    return { id: stage, name: getContactStageName(stage) };
  });
}

const ContactStagesForm = ({ currentCompany, onRedirect, ...props }) => {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);
  const [selectedStages, setSelectedStages] = useState([]);
  const [unselectedStages, setUnselectedStages] = useState([]);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = () => {
    setSaving(true);

    const contactStages = selectedStages.map((s) => s.id);

    const input = {
      id: currentCompany.id,
      contactStages,
      defaultContactStage: stripToNull(form.defaultContactStage)
    };
    props.saveCompany({ input })
      .then(() => toast.success(i18n.t('messages.saved', i18nOpts)))
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const onCancel = () => {
    onRedirect();
  };

  const loadForm = () => {
    const allStages = Object.keys(ContactStages);
    const defaultSelectedStages = currentCompany.contactStages || allStages;
    const defaultUnselectedStages = _.without(allStages, ...defaultSelectedStages);

    setSelectedStages(getStages(defaultSelectedStages));
    setUnselectedStages(getStages(defaultUnselectedStages));

    const formData = { defaultContactStage: currentCompany.defaultContactStage };
    setForm(formData);
  };

  useEffect(() => {
    const isValidDefaultStage = selectedStages.some((stage) => stage.id
      === form.defaultContactStage);
    if (!isValidDefaultStage) setForm({ defaultContactStage: null });
  }, [selectedStages]);

  useEffect(() => {
    loadForm();
  }, []);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <h2 className="mb-4">{i18n.t('title', i18nOpts)}</h2>

      <p dangerouslySetInnerHTML={{ __html: i18n.t('description', i18nOpts) }} />

      <Row className="mt-3">
        <Col>
          <Card>
            <CardHeader>
              {i18n.t('selectedStages', i18nOpts)}
            </CardHeader>
            <CardBody>
              <ReactSortable
                className="sortable-contact-stages"
                group="contact-stages"
                list={selectedStages}
                setList={setSelectedStages}
              >
                {selectedStages.map((stage) => (
                  <StageItem key={`stage-${stage.id}`} stage={stage} />
                ))}
              </ReactSortable>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card>
            <CardHeader>
              {i18n.t('unselectedStages', i18nOpts)}
            </CardHeader>

            <CardBody>
              <ReactSortable
                className="sortable-contact-stages"
                group="contact-stages"
                list={unselectedStages}
                setList={setUnselectedStages}
              >
                {unselectedStages.map((stage) => (
                  <StageItem key={`stage-${stage.id}`} stage={stage} />
                ))}
              </ReactSortable>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs="12" sm="8" md="6" lg="4">
          <FormGroup>
            <Label for="defaultContactStage">{i18n.t('defaultContactStage', i18nOpts)}</Label>
            <Input
              type="select"
              name="defaultContactStage"
              id="defaultContactStage"
              value={form.defaultContactStage || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'defaultContactStage')}
            >
              <option value="">{i18n.t('select.none')}</option>
              {
                selectedStages.map((s) => (
                  <option key={`default-stage-${s.id}`} value={s.id}>
                    {s.name}
                  </option>
                ))
              }
            </Input>
            <InputError name="defaultContactStage" error={error} />
          </FormGroup>
        </Col>
      </Row>

      <Button color="primary" className="mr-3" disabled={saving} onClick={onSave}>
        {saving && (<Spinner size="sm" className="mr-2" />)}
        {i18n.t('buttons.save')}
      </Button>
      <Button outline color="secondary" disabled={saving} onClick={onCancel}>
        {i18n.t('buttons.cancel')}
      </Button>
    </div>
  );
};

ContactStagesForm.propTypes = {
  onRedirect: PropTypes.func
};

ContactStagesForm.defaultProps = {
  onRedirect: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(ContactStagesForm);
