import React from 'react';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import { CKEditor } from '../../../ui';

const i18nOpts = { scope: 'components.admin.design.messages.index' };

function getInputProps(message) {
  if (['saveButtonText', 'buyButtonText', 'quickPossessionText', 'modelText', 'saveQuickPossessionButtonText'].includes(message.section)) return { type: 'text' };
  return { type: 'textarea', rows: 5 };
}

const Messages = ({ form, onTextChange }) => {
  const messages = form.messages || [];

  const needsCkEditor = (message) => message.section === 'verificationText';

  return (
    <div>
      {
        messages.map((message) => (
          <Row form key={`message-${message.section}`}>
            <Col lg="6" md="8" sm="8" xs="12">
              <FormGroup>
                <Label for={message.name}>{i18n.t(`sections.${message.section}`, i18nOpts)}</Label>
                {
                  needsCkEditor(message) ? (
                    <CKEditor
                      name={message.section}
                      data={message.text}
                      onTextChange={onTextChange}
                    />
                  ) : (
                    <Input
                      name={message.section}
                      id={message.section}
                      value={message.text || ''}
                      onChange={onTextChange}
                      {...getInputProps(message)}
                    />
                  )
                }
              </FormGroup>
            </Col>
          </Row>
        ))
      }
    </div>
  );
};

Messages.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func,
};

Messages.defaultProps = {
  onTextChange: () => {},
};

export default Messages;
