import React, { Component } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { getCompanies, cleanCompanies, archiveCompany } from '../../../../store/actions/companyActions';
import ListEmpty from '../../../common/ListEmpty';
import CompaniesTable from '../Table';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.companies.list.index' };

class Companies extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };

    this.onChangePage = this.onChangePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    props.cleanCompanies()
      .then(() => this.loadCompanies());
  }

  onChangePage(page) {
    this.loadCompanies(page);
  }

  onDelete(companyId) {
    const { props } = this;
    const { currentUser, currentCompany } = this.props;

    if (companyId === currentUser.companyId || companyId === currentCompany.id) {
      toast.error(i18n.t('errors.deleteCurrentCompany', i18nOpts));
      return;
    }

    props.archiveCompany(companyId)
      .then(() => {
        navigate('/admin/companies');
      })
      .catch(() => {});
  }

  onEdit(company) {
    navigate(`/admin/companies/form?id=${company.id}`);
  }

  loadCompanies(page = 1) {
    const { props } = this;

    const variables = {
      page
    };
    props.getCompanies(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const { companies, pagination, loading } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{i18n.t('title', i18nOpts)}</h2>
          </div>
          <div>
            <Link to="/admin/companies/form" className="btn btn-primary">
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Link>
          </div>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={companies} />

            {companies.length > 0 && (
              <CompaniesTable
                onDelete={this.onDelete}
                companies={companies}
                onEdit={this.onEdit}
                deleteText={i18n.t('buttons.archive')}
                deleteMessage={i18n.t('archiveMessage', i18nOpts)}
              />
            )}

            <div className="text-right mt-4">
              <ListPagination pagination={pagination} onPress={this.onChangePage} />
            </div>
          </>
        )}

        <div
          role="button"
          aria-hidden
          className="col-primary-1 text-decoration-underline pl-3"
          onClick={() => navigate('/admin/companies/archived')}
        >
          {i18n.t('archivedCompanies', i18nOpts)}
        </div>
      </div>
    );
  }
}

export default connect((store) => ({
  companies: store.companies.companies,
  loading: store.companies.getCompanies.loading,
  pagination: store.companies.pagination,
  currentUser: store.users.currentUser,
  currentCompany: store.companies.currentCompany
}), {
  getCompanies,
  cleanCompanies,
  archiveCompany
})(Companies);
