import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

const WorkflowItem = ({ Icon, title, children }) => (
  <Card body className="mb-3">
    <div className="d-flex align-items-center gap-3">
      <div>{Icon}</div>
      <div className="d-flex flex-column flex-grow-1">
        <span className="font-weight-600">{title}</span>
      </div>
      <div>
        {children}
      </div>
    </div>
  </Card>
);

WorkflowItem.propTypes = {
  Icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
};

WorkflowItem.defaultProps = {};

export default WorkflowItem;
