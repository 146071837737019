import React from 'react';

import WorkflowItem from '../../components/WorkflowItem';
import { VerificationTypes } from '../../../../../constants';
import { getRentalWorkflowTypeName } from '../../../../../utils/enumUtils';
import OptionalBadge from '../../components/OptionalBadge';
import IdVerificationIcon from '../../../../../assets/icons/verifications/IdVerificationIcon';
import HomeEvaluationIcon from '../../../../../assets/icons/verifications/HomeEvaluationIcon';
import FinancialVerificationIcon
  from '../../../../../assets/icons/verifications/FinancialVerificationIcon';

const RentalStep = ({ step }) => {
  const title = getRentalWorkflowTypeName(step.type);

  switch (step.type) {
    case VerificationTypes.ID_VERIFICATION:
      return (
        <WorkflowItem Icon={<IdVerificationIcon width={25} height={25} />} title={title}>
          <div className="d-flex align-items-center gap-2">
            <OptionalBadge optional={step.optional} />
          </div>
        </WorkflowItem>
      );

    case VerificationTypes.HOME_EVALUATION:
      return (
        <WorkflowItem Icon={<HomeEvaluationIcon width={25} height={25} />} title={title} />
      );

    case VerificationTypes.FINANCIAL_VERIFICATION:
      return (
        <WorkflowItem Icon={<FinancialVerificationIcon width={25} height={25} />} title={title}>
          <div className="d-flex align-items-center gap-2">
            <OptionalBadge optional={step.optional} />
          </div>
        </WorkflowItem>
      );

    default:
      return null;
  }
};

RentalStep.propTypes = {};

RentalStep.defaultProps = {};

export default RentalStep;
