import React from 'react';

const SignIcon = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" color="black" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_756_5898)">
      <path d="M15.5025 18.9317C16.4789 19.9079 21.6997 15.1613 23.7202 13.2278L21.2057 10.7134C19.2679 12.7325 14.5153 17.9387 15.5025 18.9317Z" fill="currentColor" />
      <path d="M27.5716 5.07395C26.0753 6.14208 24.2861 7.68737 22.4746 9.45018L24.9851 11.9607C26.7479 10.1492 28.2932 8.35997 29.3614 6.86372C30.5569 5.19016 31.0288 4.08295 30.6888 3.74295C30.1773 3.23138 28.1611 4.65246 27.5716 5.07395Z" fill="currentColor" />
      <path d="M5.67807 5.42905V0.524414L0.773438 5.42905H5.67807Z" fill="currentColor" />
      <path d="M15.0241 20.6758L13.3425 22.3574L12.0771 21.092L13.7016 19.4675C13.1779 18.2905 13.6271 15.7457 20.5654 8.80743C21.5993 7.77307 22.6381 6.80212 23.6363 5.9317V0H7.46872V7.21872H0.25V30.5453H23.6363V15.7835C21.5188 17.74 17.4619 21.5166 15.0241 20.6758ZM3.88919 9.00848H12.8977V10.7982H3.88919V9.00848ZM3.88919 12.588H9.25849V14.3778H3.88919V12.588ZM3.88919 16.1675H9.25849V17.9573H3.88919V16.1675ZM11.1865 26.3783C10.9384 25.8303 10.539 25.3174 10.3048 25.1618C10.2497 25.2649 10.1859 25.4108 10.1431 25.5113C9.76296 26.3929 9.20235 27.0554 8.2203 26.8947C7.14018 26.719 6.9785 25.548 6.84827 24.6068C6.79231 24.2039 6.68922 23.4558 6.53106 23.3003C6.29684 23.3265 5.77423 24.5815 5.67723 26.0688L3.89098 25.9543C3.93817 25.2168 4.28688 21.5367 6.5739 21.5367C8.2308 21.5367 8.47546 23.3046 8.62145 24.3612C8.62318 24.3743 8.62497 24.3866 8.6267 24.3997C8.88449 23.9015 9.38025 23.3265 10.2131 23.3265C11.7058 23.3265 12.646 25.2579 12.8199 25.6459L11.1865 26.3783Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_756_5898">
        <rect width="30.5455" height="30.5455" fill="white" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </svg>
);

SignIcon.propTypes = {};

SignIcon.defaultProps = {};

export default SignIcon;
