import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import { listDeals, cleanDeals } from '../../../../../store/actions/dealActions';
import { setReloadHomes } from '../../../../../store/actions/homeActions';
import AssignedToFilter from '../../components/DealList/AssignedToFilter';
import { isAdmin } from '../../../../../utils/authUtils';
import SearchBar from '../../components/DealList/SearchBar';
import FiltersButton from '../../components/DealList/FiltersButton';
import FiltersModal from '../../components/DealList/FiltersModal';
import Loading from '../../../../ui/Loading';
import ListEmpty from '../../../../common/ListEmpty';
import ListView from './ListView';
import ListPagination from '../../../../common/ListPagination';
import DealModal from '../../../../global/home/DealModal';
import { getDealFilters } from '../utils';

const i18nOpts = { scope: 'components.admin.sales.deals.list.index' };

const List = ({
  deals, loading, pagination, reloadHomes, currentCompany, filters, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState(null);

  const onChangePage = (page) => {
    loadDeals(page);
  };

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const loadDeals = (page = 1) => {
    const filter = getDealFilters(currentCompany, filters, query);
    const variables = {
      filter,
      page,
      companyId: currentCompany.id
    };

    props.listDeals(variables)
      .finally(() => {
        setMounted(true);
        props.setReloadHomes(false);
      });
  };

  useEffect(() => {
    props.cleanDeals()
      .then(() => loadDeals());
  }, []);

  useEffect(() => {
    if (!mounted) return;

    loadDeals();
  }, [filters, query]);

  useEffect(() => {
    if (!reloadHomes) return;

    loadDeals();
  }, [reloadHomes]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex gap-3 align-items-center flex-wrap justify-content-end mb-4">
        <div>
          <h2 className="mb-0">{i18n.t('title', i18nOpts)}</h2>
        </div>

        {isAdmin() && (
          <AssignedToFilter />
        )}

        <div className="mr-auto" />

        <SearchBar onSearch={onSearch} />
        <FiltersButton />
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={deals} />

          {deals.length > 0 && (
            <div className="mb-4">
              <ListView />
            </div>
          )}

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}

      <FiltersModal />
      <DealModal />
    </div>
  );
};

export default connect((store) => ({
  deals: store.deals.deals,
  loading: store.deals.listDeals.loading,
  pagination: store.deals.pagination,
  reloadHomes: store.homes.reloadHomes,
  currentCompany: store.companies.currentCompany,
  filters: store.offers.filters
}), {
  listDeals,
  cleanDeals,
  setReloadHomes
})(List);
