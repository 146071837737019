import React from 'react';

const IdVerificationIcon = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" color="#475467" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_567_2117)">
      <path d="M2.66636 14.5449C3.06807 14.6666 3.61067 14.6666 4.53301 14.6666H11.4663C12.3887 14.6666 12.9313 14.6666 13.333 14.5449M2.66636 14.5449C2.58022 14.5188 2.50056 14.4871 2.42503 14.4486C2.0487 14.2569 1.74274 13.9509 1.55099 13.5746C1.33301 13.1467 1.33301 12.5867 1.33301 11.4666V4.53325C1.33301 3.41315 1.33301 2.85309 1.55099 2.42527C1.74274 2.04895 2.0487 1.74299 2.42503 1.55124C2.85285 1.33325 3.4129 1.33325 4.53301 1.33325H11.4663C12.5864 1.33325 13.1465 1.33325 13.5743 1.55124C13.9506 1.74299 14.2566 2.04895 14.4484 2.42527C14.6663 2.85309 14.6663 3.41315 14.6663 4.53325V11.4666C14.6663 12.5867 14.6663 13.1467 14.4484 13.5746C14.2566 13.9509 13.9506 14.2569 13.5743 14.4486C13.4988 14.4871 13.4191 14.5188 13.333 14.5449M2.66636 14.5449C2.66658 14.0053 2.66981 13.7198 2.71758 13.4797C2.928 12.4218 3.75493 11.5949 4.81277 11.3845C5.07036 11.3333 5.38013 11.3333 5.99967 11.3333H9.99967C10.6192 11.3333 10.929 11.3333 11.1866 11.3845C12.2444 11.5949 13.0714 12.4218 13.2818 13.4797C13.3295 13.7198 13.3328 14.0053 13.333 14.5449M10.6663 6.33325C10.6663 7.80601 9.47243 8.99992 7.99967 8.99992C6.52692 8.99992 5.33301 7.80601 5.33301 6.33325C5.33301 4.86049 6.52692 3.66659 7.99967 3.66659C9.47243 3.66659 10.6663 4.86049 10.6663 6.33325Z" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_567_2117">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IdVerificationIcon.propTypes = {};

IdVerificationIcon.defaultProps = {};

export default IdVerificationIcon;
