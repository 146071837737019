import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  Button, FormGroup, Input, Label, ModalBody, ModalFooter, Spinner, Row, Col
} from 'reactstrap';
import { toast } from 'react-toastify';
import v from 'voca';
import { connect } from 'react-redux';

import { isInputInvalid } from '../../../../ui/InputError';
import { InputError } from '../../../../ui';
import PhoneNumberInput from '../../../PhoneNumberInput';
import { getGraphQLErrors } from '../../../../../utils/requestUtils';
import { saveCustomer } from '../../../../../store/actions/customerActions';
import { stripToEmpty } from '../../../../../utils/stringUtils';
import ExistingCustomerModal from './ExistingCustomerModal';
import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import { addInvitedCustomers } from '../utils';
import CopyInviteLinkButton from '../components/CopyInviteLinkButton';
import useHomeRental from '../../../../../hooks/useHomeRental';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.newCustomer.index' };

const EXISTING_CUSTOMER_ERROR = 'Customer already exists';

const NewCustomer = ({ currentCompany, onContinue, ...props }) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [adding, setAdding] = useState(false);
  const { typeI18nKey } = useHomeRental();

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onPhoneNumberChange = (value) => {
    const parsedObject = { target: { name: 'phone', value } };
    onTextChange(parsedObject);
  };

  const onAddNew = () => {
    setAdding(true);

    const companyId = currentCompany.id;
    const input = {
      ...form,
      lastName: stripToEmpty(form.lastName),
      email: stripToEmpty(form.email),
      companyId
    };
    const variables = { input, companyId };

    props.saveCustomer(variables)
      .then(({ value: { data: { item } } }) => item)
      .then((addedCustomer) => {
        addInvitedCustomers(addedCustomer);
        onContinue();
      })
      .catch((e) => {
        const apiError = getGraphQLErrors(e);
        if (v.isString(apiError)) {
          if (apiError === EXISTING_CUSTOMER_ERROR) openExistingCustomerModal();
          else toast.error(apiError);
        }
        setError(apiError);
      })
      .finally(() => setAdding(false));
  };

  const onAddExisting = (customer) => {
    addInvitedCustomers(customer);
    onContinue();
  };

  const openExistingCustomerModal = () => {
    ModalService.open(ModalNames.EXISTING_CUSTOMER, { email: stripToEmpty(form.email) });
  };

  return (
    <>
      <ModalBody className="pt-0">
        <div className="font-18 font-weight-600 mb-1">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</div>
        <div className="font-14 mb-4">{i18n.t(`${typeI18nKey}.description`, i18nOpts)}</div>

        <Row>
          <Col>
            <FormGroup>
              <Label for="firstName">{i18n.t('firstName', i18nOpts)}</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={form.firstName || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'firstName')}
              />
              <InputError name="firstName" error={error} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="lastName">{i18n.t('lastName', i18nOpts)}</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={form.lastName || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'lastName')}
              />
              <InputError name="lastName" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for="email">{i18n.t('email', i18nOpts)}</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={form.email || ''}
            invalid={isInputInvalid(error, 'email')}
            onChange={onTextChange}
          />
          <InputError name="email" error={error} />
        </FormGroup>

        <FormGroup className="mb-0">
          <Label for="phone">{i18n.t('phone', i18nOpts)}</Label>
          <PhoneNumberInput
            value={form.phone || ''}
            onChange={onPhoneNumberChange}
          />
          <InputError name="phone" error={error} />
        </FormGroup>
      </ModalBody>

      <ModalFooter className="gap-2">
        <div className="flex-even">
          <CopyInviteLinkButton />
        </div>
        <div className="flex-even">
          <Button color="primary" onClick={onAddNew} block disabled={adding}>
            {adding && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.add')}
          </Button>
        </div>
      </ModalFooter>

      <ExistingCustomerModal onAdd={onAddExisting} />
    </>
  );
};

NewCustomer.propTypes = {
  onContinue: PropTypes.func
};

NewCustomer.defaultProps = {
  onContinue: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  invitedCustomers: store.salesInvitation.invitedCustomers
}), {
  saveCustomer,
})(NewCustomer);
