import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ModalBody, ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';

import ListView from './ListView';
import CopyInviteLinkButton from '../components/CopyInviteLinkButton';
import useHomeRental from '../../../../../hooks/useHomeRental';
import { Steps } from '../constants';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.invitationList.index' };

const InvitationList = ({
  invitedCustomers, onContinue, onSkipped
}) => {
  const { typeI18nKey } = useHomeRental();

  const onSelectOrInvite = () => {
    onContinue(Steps.CONFIRM_INVITATION);
  };

  const hasSomeUnselectedInviteMethod = useMemo(() => invitedCustomers
    .some((ic) => v.isBlank(ic.inviteMethod)),
  [invitedCustomers]);

  const someDontHaveEmailAndPhone = useMemo(() => invitedCustomers
    .some((ic) => v.isBlank(ic.email) || v.isBlank(ic.phone)),
  [invitedCustomers]);

  useEffect(() => {
    if (someDontHaveEmailAndPhone) {
      onSkipped();
      onSelectOrInvite();
    }
  }, [someDontHaveEmailAndPhone]);

  return (
    <>
      <ModalBody className="not-scrollable-container d-flex flex-column p-0">
        <div className="pt-0 px-3 pb-3">
          <div className="font-18 font-weight-600">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</div>
        </div>

        <div className="scrollable-container">
          <ListView />
        </div>
      </ModalBody>

      <ModalFooter className="gap-2">
        <div className="flex-even">
          <CopyInviteLinkButton />
        </div>
        <div className="flex-even">
          <Button
            color="primary"
            onClick={onSelectOrInvite}
            block
            disabled={!invitedCustomers.length || hasSomeUnselectedInviteMethod}
          >
            {i18n.t('buttons.select')}
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

InvitationList.propTypes = {
  onContinue: PropTypes.func,
  onSkipped: PropTypes.func,
};

InvitationList.defaultProps = {
  onContinue: () => {},
  onSkipped: () => {},
};

export default connect((store) => ({
  invitedCustomers: store.salesInvitation.invitedCustomers
}))(InvitationList);
