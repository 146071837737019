import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup, Input, Label, Collapse
} from 'reactstrap';
import i18n from 'i18n-js';
import Switch from 'rc-switch';

import { parseBoolean } from '../../../../../utils/parserUtils';

const i18nOpts = { scope: 'components.admin.companies.form.reportSettings.index' };

const ReportSettings = ({ form, onChange }) => {
  const settings = form.reportSettings || {};

  const onToggle = (name) => {
    const reportSettings = form.reportSettings || {};
    reportSettings[name] = !reportSettings[name];
    if (name === 'enabled' && !reportSettings[name]) {
      reportSettings.enabledFinancialReport = false;
      reportSettings.enabledFinancialHighlights = false;
    }
    if (name === 'enabledFinancialReport' && !reportSettings[name]) {
      reportSettings.enabledFinancialHighlights = false;
    }
    onChange(reportSettings);
  };

  return (
    <div className="mb-4">
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            name="enabled"
            id="enabled"
            value={!parseBoolean(settings.enabled)}
            checked={parseBoolean(settings.enabled)}
            onChange={() => onToggle('enabled')}
          />
          {i18n.t('enabled', i18nOpts)}
        </Label>
      </FormGroup>

      <Collapse isOpen={settings.enabled || false} className="pl-4 pt-2">
        <div className="d-inline-block">
          <span onClick={() => onToggle('enabledFinancialReport')} role="button" aria-hidden className="d-flex align-items-center">
            <Switch checked={settings.enabledFinancialReport || false} />
            <span className="ml-2">{i18n.t('financialReport', i18nOpts)}</span>
          </span>
        </div>
        <br />
        <Collapse isOpen={settings.enabledFinancialReport || false}>
          <div className="d-inline-block mt-3">
            <span onClick={() => onToggle('enabledFinancialHighlights')} role="button" aria-hidden className="d-flex align-items-center">
              <Switch checked={settings.enabledFinancialHighlights || false} />
              <span className="ml-2">{i18n.t('financialHighlights', i18nOpts)}</span>
            </span>
          </div>
        </Collapse>
      </Collapse>
    </div>
  );
};

ReportSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

ReportSettings.defaultProps = {
  onChange: () => {}
};

export default ReportSettings;
