import React from 'react';

const FinancialVerificationIcon = ({ ...props }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" color="#475467" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.5 9.00002V17M10 9.00002V17M15 9.00002V17M19.5 9.00002V17M3.5 18.6L3.5 19.4C3.5 19.9601 3.5 20.2401 3.60899 20.454C3.70487 20.6422 3.85785 20.7952 4.04601 20.891C4.25992 21 4.53995 21 5.1 21H19.9C20.4601 21 20.7401 21 20.954 20.891C21.1422 20.7952 21.2951 20.6422 21.391 20.454C21.5 20.2401 21.5 19.9601 21.5 19.4V18.6C21.5 18.04 21.5 17.7599 21.391 17.546C21.2951 17.3579 21.1422 17.2049 20.954 17.109C20.7401 17 20.4601 17 19.9 17H5.1C4.53995 17 4.25992 17 4.04601 17.109C3.85785 17.2049 3.70487 17.3579 3.60899 17.546C3.5 17.7599 3.5 18.04 3.5 18.6ZM12.1529 3.07715L4.75291 4.7216C4.30585 4.82094 4.08232 4.87062 3.91546 4.99082C3.76829 5.09685 3.65273 5.24092 3.58115 5.40759C3.5 5.59654 3.5 5.82553 3.5 6.28349L3.5 7.40002C3.5 7.96007 3.5 8.2401 3.60899 8.45401C3.70487 8.64217 3.85785 8.79515 4.04601 8.89103C4.25992 9.00002 4.53995 9.00002 5.1 9.00002H19.9C20.4601 9.00002 20.7401 9.00002 20.954 8.89103C21.1422 8.79515 21.2951 8.64217 21.391 8.45401C21.5 8.2401 21.5 7.96007 21.5 7.40002V6.2835C21.5 5.82553 21.5 5.59655 21.4188 5.40759C21.3473 5.24092 21.2317 5.09685 21.0845 4.99082C20.9177 4.87062 20.6942 4.82094 20.2471 4.7216L12.8471 3.07715C12.7176 3.04837 12.6528 3.03398 12.5874 3.02824C12.5292 3.02314 12.4708 3.02314 12.4126 3.02824C12.3472 3.03398 12.2824 3.04837 12.1529 3.07715Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

FinancialVerificationIcon.propTypes = {};

FinancialVerificationIcon.defaultProps = {};

export default FinancialVerificationIcon;
