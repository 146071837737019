import React from 'react';

import WorkflowItem from '../../components/WorkflowItem';
import { VerificationTypes } from '../../../../../constants';
import {
  getVerifiedWorkflowTypeName
} from '../../../../../utils/enumUtils';
import OptionalBadge from '../../components/OptionalBadge';
import IdVerificationIcon from '../../../../../assets/icons/verifications/IdVerificationIcon';
import HomeEvaluationIcon from '../../../../../assets/icons/verifications/HomeEvaluationIcon';
import FinancialVerificationIcon
  from '../../../../../assets/icons/verifications/FinancialVerificationIcon';

const VerifiedStep = ({ step }) => {
  const title = getVerifiedWorkflowTypeName(step.type);

  switch (step.type) {
    case VerificationTypes.ID_VERIFICATION:
      return (
        <WorkflowItem Icon={<IdVerificationIcon width={25} height={25} />} title={title}>
          <OptionalBadge optional={step.optional} />
        </WorkflowItem>
      );

    case VerificationTypes.HOME_EVALUATION:
      return (
        <WorkflowItem Icon={<HomeEvaluationIcon width={25} height={25} />} title={title}>
          <OptionalBadge optional={step.optional} />
        </WorkflowItem>
      );

    case VerificationTypes.FINANCIAL_VERIFICATION:
      return (
        <WorkflowItem Icon={<FinancialVerificationIcon width={25} height={25} />} title={title}>
          <OptionalBadge optional={step.optional} />
        </WorkflowItem>
      );

    default:
      return null;
  }
};

VerifiedStep.propTypes = {};

VerifiedStep.defaultProps = {};

export default VerifiedStep;
