import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { Tabs } from '../utils';
import { isPreApprovalEnabled } from '../../../../utils/companyUtils';

const i18nOpts = { scope: 'components.admin.mortgageRate.navTabs.index' };

const NavTabs = ({ activeTab, setActiveTab }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        active={activeTab === Tabs.MORTGAGE_RATE}
        onClick={() => setActiveTab(Tabs.MORTGAGE_RATE)}
      >
        {i18n.t('mortgageRate', i18nOpts)}
      </NavLink>
    </NavItem>

    {isPreApprovalEnabled() && (
      <NavItem>
        <NavLink
          active={activeTab === Tabs.MORTGAGE_PROVIDERS}
          onClick={() => setActiveTab(Tabs.MORTGAGE_PROVIDERS)}
        >
          {i18n.t('mortgageProviders', i18nOpts)}
        </NavLink>
      </NavItem>
    )}
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
};

NavTabs.defaultProps = {};

export default NavTabs;
