import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Row, Col, Button, Spinner, Collapse
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import v from 'voca';
import _ from 'lodash';

import { saveCompany, getCompany } from '../../../../store/actions/companyActions';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues, isUnsupportedImageError } from '../../../../utils/formUtils';
import './styles.scss';
import FileUploader from '../../../common/FileUploader';
import ImageViewer from '../../../common/ImageViewer';
import { parseBoolean } from '../../../../utils/parserUtils';
import { stripToEmpty, stripToNull } from '../../../../utils/stringUtils';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import ReserveNowSettings from './ReserveNowSettings';
import { getIpsArray, isValidIPAddresses } from '../../../../utils/ipUtils';
import {
  DefaultHomePage,
  EmailServices,
  HomePriceDisplayModes,
  IdVerificationProviders, PreApprovalVerificationMethods
} from '../../../../constants';
import {
  getDefaultHomePageName,
  getHomePriceDisplayModeName,
  getIdVerificationProviderName
} from '../../../../utils/enumUtils';
import { isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import { MultiSelect } from '../../../ui';
import LocationSelector from '../../../ui/LocationSelector';
import { fetchSalesRepsAsync } from '../../../../hooks/useFetchSalesReps';
import ReportSettings from './ReportSettings';
import { isCanadianCountry } from '../../Customers/Show/Overview/PropertyCard/utils';

const i18nOpts = { scope: 'components.admin.companies.form.index' };

function onCancel() {
  navigate('/admin/companies');
}

const DomainField = ({
  index,
  onChange,
  value,
  error,
  isLast
}) => (
  <FormGroup>
    <Input
      type="text"
      name={`domains-${index}`}
      id={`domains-${index}`}
      value={value || ''}
      onChange={onChange}
      invalid={isInputInvalid(error, 'domains')}
    />
    {isLast && (
      <>
        <span className="d-block mt-2" />
        <InputError name="domains" error={error} />
      </>
    )}
  </FormGroup>
);

const CompanyEmailServices = Object.freeze([
  { value: EmailServices.AWS_SES, label: i18n.t('emailServices.awsSes') },
  { value: EmailServices.SENDGRID, label: i18n.t('emailServices.sendGrid') }
]);

class CompanyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {},
      saving: false,
      domains: [],
      logo: undefined,
      secondaryLogo: undefined,
      icon: undefined,
      error: null,
      salesRepresentatives: []
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAddDomain = this.onAddDomain.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.onReserveNowSettingsChange = this.onReserveNowSettingsChange.bind(this);
    this.onReportSettingsChange = this.onReportSettingsChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
  }

  componentDidMount() {
    const { companyId } = this.props;
    const id = companyId || parseInt(getParam('id'), 10);
    this.setState({ id }, this.loadCompany);
    this.loadSalesRepresentatives(id);
  }

  onTextChange(event) {
    const { form, domains } = this.state;
    const { name, value } = event.target;

    if (name.startsWith('domains')) {
      const domainIndex = name.split('-')[1];
      const sanitizedIndex = parseInt(domainIndex, 10);
      const clonedDomains = [...domains];
      clonedDomains[sanitizedIndex] = value;
      this.setState({ domains: clonedDomains });
    } else form[name] = value;

    this.setState({ form });
  }

  onSelect(name, selectedItems) {
    const { form } = this.state;
    form[name] = selectedItems;
    this.setState({ form });
  }

  onReserveNowSettingsChange(newSettings) {
    const { form } = this.state;
    form.buyNowSettings = newSettings;
    this.setState({ form });
  }

  onReportSettingsChange(newSettings) {
    const { form } = this.state;
    form.reportSettings = newSettings;
    this.setState({ form });
  }

  onAddDomain() {
    const { domains } = this.state;
    domains.push('');
    this.setState({ domains });
  }

  onDrop(images, imageType) {
    if (images.length > 0) {
      const image = images[0];
      this.setState({ [imageType]: image });
    }
  }

  onSubmit(event) {
    event.preventDefault();

    const { props } = this;
    const {
      form, logo, secondaryLogo, icon, domains = []
    } = this.state;

    const filteredDomains = domains.filter((d) => !v.isBlank(d));
    const enableNewClientUI = parseBoolean(form.enableNewClientUI);
    const enableNewClientUIPreview = parseBoolean(form.enableNewClientUIPreview)
      && !enableNewClientUI;
    const enableIdVerification = parseBoolean(form.enableIdVerification);
    const enablePreApprovalVerification = parseBoolean(form.enablePreApprovalVerification);
    const enableVerifiedBuyingPower = parseBoolean(form.enableVerifiedBuyingPower);
    const enableInviteCustomer = enableVerifiedBuyingPower
      && parseBoolean(form.enableInviteCustomer);
    const enableRequestCustomerVerification = enableVerifiedBuyingPower
      && parseBoolean(form.enableRequestCustomerVerification);
    if (!v.isBlank(form.ipToExclude) && !isValidIPAddresses(form.ipToExclude)) {
      toast.error(i18n.t('messages.trafficError', i18nOpts));
      return;
    }
    const excludeTrafficSettings = { excludedIPs: getIpsArray(form.ipToExclude) };
    const leadNotificationSalesRepresentativeIds = (form.leadNotificationSalesRepresentatives || [])
      .map((i) => i.id);
    const enableNewHomeNavigation = parseBoolean(form.enableNewHomeNavigation);
    const enableDemoModeForHomewise = parseBoolean(form.enableDemoModeForHomewise);

    const enableHomeSales = parseBoolean(form.enableHomeSales);
    const enableHomeRental = parseBoolean(form.enableHomeRental) && !enableHomeSales;

    const input = {
      name: stripToEmpty(form.name),
      countryCode: stripToEmpty(form.countryCode),
      country: stripToEmpty(form.country),
      stateCode: stripToEmpty(form.stateCode),
      state: stripToEmpty(form.state),
      city: stripToEmpty(form.city),
      domains: filteredDomains || [],
      logoRedirectUrl: stripToNull(form.logoRedirectUrl),
      senderEmail: stripToNull(form.senderEmail),
      enableSampleData: parseBoolean(form.enableSampleData),
      enablePickingALot: parseBoolean(form.enablePickingALot),
      enableNewClientUI,
      enableNewClientUIPreview,
      enableIdVerification,
      enableExitPageReminder: parseBoolean(form.enableExitPageReminder),
      enableVerifiedBuyingPower,
      enablePreApprovalVerification,
      enableInviteCustomer,
      enableRequestCustomerVerification,
      enableCaptureLeadsFromBeginning: parseBoolean(form.enableCaptureLeadsFromBeginning),
      enableHomeSales,
      enableSingleFamilyHomes: enableHomeSales && parseBoolean(form.enableSingleFamilyHomes),
      enableMultiFamilyHomes: enableHomeSales && parseBoolean(form.enableMultiFamilyHomes),
      enableHomeRental,
      enableSingleFamilyHomesRental: enableHomeRental
        && parseBoolean(form.enableSingleFamilyHomesRental),
      enableMultiFamilyHomesRental: enableHomeRental
        && parseBoolean(form.enableMultiFamilyHomesRental),
      buyNowSettings: form.buyNowSettings,
      excludeTrafficSettings,
      idVerificationProvider: form.idVerificationProvider,
      preApprovalVerificationProvider: stripToNull(form.preApprovalVerificationProvider),
      emailService: form.emailService,
      homePriceDisplayMode: form.homePriceDisplayMode,
      leadNotificationSalesRepresentativeIds,
      enableNewHomeNavigation,
      enableDemoModeForHomewise,
      defaultHomePage: form.defaultHomePage,
      enableACHPayments: parseBoolean(form.enableACHPayments),
      reportSettings: form.reportSettings,
      enableDemoModeForVerified: parseBoolean(form.enableDemoModeForVerified),
      enableDemoModeForReserveNow: parseBoolean(form.enableDemoModeForReserveNow),
      enableContactPhoneRequired: parseBoolean(form.enableContactPhoneRequired),
      enableAirMilesForVerified: parseBoolean(form.enableAirMilesForVerified),
      enableAirMilesForReserveNow: parseBoolean(form.enableAirMilesForReserveNow)
    };

    if (isMasterAdmin()) {
      input.enableCORSForJavaScriptSDK = parseBoolean(form.enableCORSForJavaScriptSDK);
    }

    if (!this.isNew()) input.id = form.id;

    this.setState({ saving: true });

    const variables = {
      input, image: logo, secondaryImage: secondaryLogo, icon
    };
    props.saveCompany(variables)
      .then(() => {
        toast.success(i18n.t('messages.success', i18nOpts));
        setTimeout(() => this.navigateAfterSaving(), 1500);
      })
      .catch((e) => {
        if (isUnsupportedImageError(e)) toast.error(e.message);

        const error = getError(e);
        this.setState({ saving: false, error });
        if (v.isString(error)) toast.error(error);
      });
  }

  onDeleteImage(imageType) {
    this.setState({ [imageType]: null });
  }

  onLocationChange(location) {
    this.setState((prevState) => ({ form: { ...prevState.form, ...location } }));
  }

  getPreviewImageUrls() {
    const { company } = this.props;
    const { logo, secondaryLogo, icon } = this.state;

    const logoUrl = logo === null ? null : (getLocalImageUrl(logo) || company?.logoUrl);
    const secondaryLogoUrl = secondaryLogo === null ? null
      : (getLocalImageUrl(secondaryLogo) || company?.secondaryLogoUrl);
    const iconUrl = icon === null ? null : (getLocalImageUrl(icon) || company?.iconUrl);

    return [logoUrl, secondaryLogoUrl, iconUrl];
  }

  getExcludeTrafficSettings(excludeTrafficSettings) {
    return excludeTrafficSettings?.excludedIPs.join(', ') || '';
  }

  navigateAfterSaving() {
    const { companyId } = this.props;
    const redirectPath = companyId ? '/' : '/admin/companies';
    navigate(redirectPath);
  }

  processSalesRepresentatives(salesRepresentatives) {
    salesRepresentatives.forEach((sr) => {
      // eslint-disable-next-line no-param-reassign
      sr.displayValue = `${sr.name} - ${sr.email}`;
    });
  }

  loadCompany() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) {
      this.setState({
        form: {
          enableHomeSales: true,
          enableSingleFamilyHomes: true,
          enableNewClientUI: true,
          idVerificationProvider: IdVerificationProviders.ONFIDO,
          preApprovalVerificationProvider: PreApprovalVerificationMethods.HOMEWISE,
          defaultHomePage: DefaultHomePage.MY_HOMES
        }
      });
      return;
    }

    props.getCompany(id)
      .then(() => {
        const { company } = this.props;

        if (company) {
          this.processSalesRepresentatives(company.leadNotificationSalesRepresentatives || []);
          const { domains, excludeTrafficSettings } = company;
          if (domains.length === 0) domains.push('');
          this.setState({
            form: {
              ...company,
              ipToExclude: this.getExcludeTrafficSettings(excludeTrafficSettings)
            },
            domains
          });
        } else navigate('/admin/companies');
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .catch(() => navigate('/admin/companies'));
  }

  hasFormValues() {
    const { form } = this.state;
    return hasFormValues(form);
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  loadSalesRepresentatives(companyId) {
    if (!companyId) return;

    fetchSalesRepsAsync(companyId)
      .then((salesRepresentatives) => {
        this.processSalesRepresentatives(salesRepresentatives);
        this.setState({ salesRepresentatives });
      })
      .catch((e) => toast.error(e.message));
  }

  render() {
    const {
      form, saving, domains, error, salesRepresentatives
    } = this.state;

    const { currentUser, company } = this.props;

    const [logoUrl, secondaryLogoUrl, iconUrl] = this.getPreviewImageUrls();

    const isNew = this.isNew();
    const hasValues = this.hasFormValues();

    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    const isSendGridConnected = !v.isBlank(company?.sendGridSettings?.apiKey);
    const isMasterAdminRole = isMasterAdmin();
    const isTecSupportRole = isTecSupport();
    const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;
    const isHomewiseEnabled = form.preApprovalVerificationProvider
      === PreApprovalVerificationMethods.HOMEWISE;

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Form onSubmit={this.onSubmit}>
          <Row>
            <Col lg="4" md="6" sm="8" xs="12">
              <div className="mb-4">
                <FormGroup>
                  <Label for="name">{i18n.t('name', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={form.name || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'name')}
                  />
                  <InputError name="name" error={error} />
                </FormGroup>
              </div>

              {!_.isEmpty(form) && (
                <div className="mb-4">
                  <LocationSelector
                    defaultCountryCode={form.countryCode}
                    defaultStateCode={form.stateCode}
                    defaultCity={form.city}
                    onLocationChange={this.onLocationChange}
                    errors={error}
                  />
                </div>
              )}
              <div>
                <div className="font-weight-bold mb-2">{i18n.t('logo', i18nOpts)}</div>
                {logoUrl && (
                  <ImageViewer url={logoUrl} onDelete={() => this.onDeleteImage('logo')} />
                )}
              </div>
              <div className="mb-4">
                <FileUploader
                  onUpload={(images) => this.onDrop(images, 'logo')}
                  label={i18n.t('dragAndDrop', i18nOpts)}
                  maxFiles={1}
                />
              </div>
              <div className="mb-4">
                <FormGroup>
                  <Label for="logoRedirectUrl">{i18n.t('logoRedirectUrl', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="logoRedirectUrl"
                    id="logoRedirectUrl"
                    value={form.logoRedirectUrl || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'logoRedirectUrl')}
                  />
                  <InputError name="logoRedirectUrl" error={error} />
                </FormGroup>
              </div>
              <div>
                <div className="font-weight-bold mb-2">{i18n.t('secondaryLogo', i18nOpts)}</div>
                {secondaryLogoUrl && (
                  <ImageViewer
                    url={secondaryLogoUrl}
                    onDelete={() => this.onDeleteImage('secondaryLogo')}
                  />
                )}
              </div>
              <div className="mb-4">
                <FileUploader
                  onUpload={(images) => this.onDrop(images, 'secondaryLogo')}
                  label={i18n.t('dragAndDrop', i18nOpts)}
                  maxFiles={1}
                />
              </div>

              <div>
                <div className="font-weight-bold mb-2">{i18n.t('icon', i18nOpts)}</div>
                {iconUrl && (
                  <ImageViewer
                    url={iconUrl}
                    onDelete={() => this.onDeleteImage('icon')}
                    size="w-25"
                  />
                )}
              </div>
              <div className="mb-4">
                <FileUploader
                  onUpload={(images) => this.onDrop(images, 'icon')}
                  label={i18n.t('dragAndDrop', i18nOpts)}
                  maxFiles={1}
                />
              </div>

              {canViewMasterSettings && (
                <>
                  <div className="mb-2">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <span className="font-weight-bold">{i18n.t('domains', i18nOpts)}</span>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={this.onAddDomain}
                          className="mb-2"
                        >
                          <i className="fas fa-plus mr-2" />
                          {i18n.t('buttons.add', i18nOpts)}
                        </Button>
                      </div>
                    </div>

                    <div className="w-100">
                      {domains && domains.map((domain, index) => (
                        <DomainField
                          key={`domain-${index}`}
                          index={index}
                          domain={domain}
                          onChange={this.onTextChange}
                          value={domain}
                          error={error}
                          isLast={index === domains.length - 1}
                        />
                      ))}
                    </div>
                  </div>

                  <div>
                    <FormGroup>
                      <Label for="defaultHomePage">{i18n.t('defaultHomePage', i18nOpts)}</Label>
                      <Input
                        type="select"
                        name="defaultHomePage"
                        id="defaultHomePage"
                        value={form.defaultHomePage || ''}
                        onChange={this.onTextChange}
                        invalid={isInputInvalid(error, 'defaultHomePage')}
                      >
                        {Object.values(DefaultHomePage).map((page) => (
                          <option key={`default-home-page-option-${page}`} value={page}>
                            {getDefaultHomePageName(page)}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>

                  <div>
                    <FormGroup>
                      <Label for="senderEmail">{i18n.t('senderEmail', i18nOpts)}</Label>
                      <Input
                        type="text"
                        name="senderEmail"
                        id="senderEmail"
                        value={form.senderEmail || ''}
                        onChange={this.onTextChange}
                        invalid={isInputInvalid(error, 'senderEmail')}
                      />
                      <InputError name="senderEmail" error={error} />
                    </FormGroup>
                  </div>

                  <div>
                    <FormGroup>
                      <Label for="emailService">{i18n.t('emailService', i18nOpts)}</Label>
                      <Input
                        type="select"
                        name="emailService"
                        id="emailService"
                        value={form.emailService || ''}
                        onChange={this.onTextChange}
                        invalid={isInputInvalid(error, 'emailService')}
                      >
                        {
                          CompanyEmailServices.map((es) => (
                            <option
                              key={`email-service-option-${es.value}`}
                              value={es.value}
                              disabled={es.value === EmailServices.SENDGRID && !isSendGridConnected}
                            >
                              {es.label}
                            </option>
                          ))
                        }
                      </Input>
                    </FormGroup>
                  </div>
                </>
              )}

              <FormGroup>
                <Label for="homePriceDisplayMode">{i18n.t('homePriceDisplayMode', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="homePriceDisplayMode"
                  id="homePriceDisplayMode"
                  value={form.homePriceDisplayMode || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'homePriceDisplayMode')}
                >
                  {
                    Object.values(HomePriceDisplayModes).map((dm) => (
                      <option key={`home-price-display-mode-option-${dm}`} value={dm}>
                        {getHomePriceDisplayModeName(dm)}
                      </option>
                    ))
                  }
                </Input>
              </FormGroup>

              <div className="multi-select-container mb-3">
                <Label>{i18n.t('leadNotificationSalesRepresentatives', i18nOpts)}</Label>
                <MultiSelect
                  id="sales-representatives-multiselect"
                  options={salesRepresentatives}
                  selectedValues={form.leadNotificationSalesRepresentatives || []}
                  onSelect={(items) => this.onSelect('leadNotificationSalesRepresentatives', items)}
                  onRemove={(items) => this.onSelect('leadNotificationSalesRepresentatives', items)}
                  displayValue="displayValue"
                  closeIcon="cancel"
                  emptyRecordMsg={i18n.t('emptySalesRepresentatives', i18nOpts)}
                  placeholder=""
                />
              </div>

              {canViewMasterSettings && (
                <>
                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableSampleData"
                          id="enableSampleData"
                          value={!parseBoolean(form.enableSampleData)}
                          checked={parseBoolean(form.enableSampleData)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableSampleData', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enablePickingALot"
                          id="enablePickingALot"
                          value={!parseBoolean(form.enablePickingALot)}
                          checked={parseBoolean(form.enablePickingALot)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enablePickingALot', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>
                </>
              )}

              <div className="mb-3">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="enableHomeSales"
                      id="enableHomeSales"
                      value={!parseBoolean(form.enableHomeSales)}
                      checked={parseBoolean(form.enableHomeSales)}
                      onChange={this.onTextChange}
                    />
                    {i18n.t('enableHomeSales', i18nOpts)}
                  </Label>
                </FormGroup>
              </div>

              <Collapse isOpen={parseBoolean(form.enableHomeSales)} className="pl-4">
                <div className="mb-3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="enableSingleFamilyHomes"
                        id="enableSingleFamilyHomes"
                        value={!parseBoolean(form.enableSingleFamilyHomes)}
                        checked={parseBoolean(form.enableSingleFamilyHomes)}
                        onChange={this.onTextChange}
                      />
                      {i18n.t('enableSingleFamilyHomes', i18nOpts)}
                    </Label>
                  </FormGroup>
                </div>

                <div className="mb-3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="enableMultiFamilyHomes"
                        id="enableMultiFamilyHomes"
                        value={!parseBoolean(form.enableMultiFamilyHomes)}
                        checked={parseBoolean(form.enableMultiFamilyHomes)}
                        onChange={this.onTextChange}
                      />
                      {i18n.t('enableMultiFamilyHomes', i18nOpts)}
                    </Label>
                  </FormGroup>
                </div>
              </Collapse>

              <div className="mb-3">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="enableHomeRental"
                      id="enableHomeRental"
                      value={!parseBoolean(form.enableHomeRental)}
                      checked={parseBoolean(form.enableHomeRental)}
                      onChange={this.onTextChange}
                    />
                    {i18n.t('enableHomeRental', i18nOpts)}
                  </Label>
                </FormGroup>
              </div>

              <Collapse isOpen={parseBoolean(form.enableHomeRental)} className="pl-4">
                <div className="mb-3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="enableSingleFamilyHomesRental"
                        id="enableSingleFamilyHomesRental"
                        value={!parseBoolean(form.enableSingleFamilyHomesRental)}
                        checked={parseBoolean(form.enableSingleFamilyHomesRental)}
                        onChange={this.onTextChange}
                      />
                      {i18n.t('enableSingleFamilyHomesRental', i18nOpts)}
                    </Label>
                  </FormGroup>
                </div>

                <div className="mb-3">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="enableMultiFamilyHomesRental"
                        id="enableMultiFamilyHomesRental"
                        value={!parseBoolean(form.enableMultiFamilyHomesRental)}
                        checked={parseBoolean(form.enableMultiFamilyHomesRental)}
                        onChange={this.onTextChange}
                      />
                      {i18n.t('enableMultiFamilyHomesRental', i18nOpts)}
                    </Label>
                  </FormGroup>
                </div>
              </Collapse>

              {canViewMasterSettings && (
                <>
                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableNewClientUI"
                          id="enableNewClientUI"
                          value={!parseBoolean(form.enableNewClientUI)}
                          checked={parseBoolean(form.enableNewClientUI)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableNewClientUI', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  <Collapse isOpen={!parseBoolean(form.enableNewClientUI)}>
                    <div className="mb-3">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="enableNewClientUIPreview"
                            id="enableNewClientUIPreview"
                            value={!parseBoolean(form.enableNewClientUIPreview)}
                            checked={parseBoolean(form.enableNewClientUIPreview)}
                            onChange={this.onTextChange}
                          />
                          {i18n.t('enableNewClientUIPreview', i18nOpts)}
                        </Label>
                      </FormGroup>
                    </div>
                  </Collapse>

                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableVerifiedBuyingPower"
                          id="enableVerifiedBuyingPower"
                          value={!parseBoolean(form.enableVerifiedBuyingPower)}
                          checked={parseBoolean(form.enableVerifiedBuyingPower)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableVerifiedBuyingPower', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  <Collapse isOpen={parseBoolean(form.enableVerifiedBuyingPower)} className="pl-4">
                    <div className="mb-3">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="enableInviteCustomer"
                            id="enableInviteCustomer"
                            value={!parseBoolean(form.enableInviteCustomer)}
                            checked={parseBoolean(form.enableInviteCustomer)}
                            onChange={this.onTextChange}
                          />
                          {i18n.t('enableInviteCustomer', i18nOpts)}
                        </Label>
                      </FormGroup>
                    </div>

                    <div className="mb-3">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="enableRequestCustomerVerification"
                            id="enableRequestCustomerVerification"
                            value={!parseBoolean(form.enableRequestCustomerVerification)}
                            checked={parseBoolean(form.enableRequestCustomerVerification)}
                            onChange={this.onTextChange}
                          />
                          {i18n.t('enableRequestCustomerVerification', i18nOpts)}
                        </Label>
                      </FormGroup>
                    </div>
                  </Collapse>

                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableIdVerification"
                          id="enableIdVerification"
                          value={!parseBoolean(form.enableIdVerification)}
                          checked={parseBoolean(form.enableIdVerification)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableIdVerification', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enablePreApprovalVerification"
                          id="enablePreApprovalVerification"
                          value={!parseBoolean(form.enablePreApprovalVerification)}
                          checked={parseBoolean(form.enablePreApprovalVerification)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enablePreApprovalVerification', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableExitPageReminder"
                          id="enableExitPageReminder"
                          value={!parseBoolean(form.enableExitPageReminder)}
                          checked={parseBoolean(form.enableExitPageReminder)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableExitPageReminder', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableCaptureLeadsFromBeginning"
                          id="enableCaptureLeadsFromBeginning"
                          value={!parseBoolean(form.enableCaptureLeadsFromBeginning)}
                          checked={parseBoolean(form.enableCaptureLeadsFromBeginning)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableCaptureLeadsFromBeginning', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  {isMasterAdminRole && (
                    <div className="mb-3">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="enableCORSForJavaScriptSDK"
                            id="enableCORSForJavaScriptSDK"
                            value={!parseBoolean(form.enableCORSForJavaScriptSDK)}
                            checked={parseBoolean(form.enableCORSForJavaScriptSDK)}
                            onChange={this.onTextChange}
                          />
                          {i18n.t('enableCORSForJavaScriptSDK', i18nOpts)}
                        </Label>
                      </FormGroup>
                    </div>
                  )}
                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableNewHomeNavigation"
                          id="enableNewHomeNavigation"
                          value={!parseBoolean(form.enableNewHomeNavigation)}
                          checked={parseBoolean(form.enableNewHomeNavigation)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableNewHomeNavigation', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableACHPayments"
                          id="enableACHPayments"
                          value={!parseBoolean(form.enableACHPayments)}
                          checked={parseBoolean(form.enableACHPayments)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableACHPayments', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>

                  <ReserveNowSettings form={form} onChange={this.onReserveNowSettingsChange} />

                  {isMasterAdminRole && (
                    <ReportSettings form={form} onChange={this.onReportSettingsChange} />
                  )}

                  <FormGroup>
                    <Label for="idVerificationProvider">{i18n.t('idVerificationProvider', i18nOpts)}</Label>
                    <Input
                      type="select"
                      name="idVerificationProvider"
                      id="idVerificationProvider"
                      value={form.idVerificationProvider || ''}
                      onChange={this.onTextChange}
                      invalid={isInputInvalid(error, 'idVerificationProvider')}
                    >
                      {
                        Object.values(IdVerificationProviders)
                          .map((ivp) => (
                            <option key={`id-verification-provider-option-${ivp}`} value={ivp}>
                              {getIdVerificationProviderName(ivp)}
                            </option>
                          ))
                      }
                    </Input>
                  </FormGroup>

                  {isCanadianCountry(form.country) && (
                    <>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            name="preApprovalVerificationProvider"
                            id="preApprovalVerificationProvider"
                            value={isHomewiseEnabled ? '' : PreApprovalVerificationMethods.HOMEWISE}
                            checked={isHomewiseEnabled}
                            onChange={this.onTextChange}
                          />
                          {i18n.t('enableHomewise', i18nOpts)}
                        </Label>
                      </FormGroup>

                      <Collapse isOpen={isHomewiseEnabled}>
                        <div className="mb-3">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                name="enableDemoModeForHomewise"
                                id="enableDemoModeForHomewise"
                                value={!parseBoolean(form.enableDemoModeForHomewise)}
                                checked={parseBoolean(form.enableDemoModeForHomewise)}
                                onChange={this.onTextChange}
                              />
                              {i18n.t('enableDemoModeForHomewise', i18nOpts)}
                            </Label>
                          </FormGroup>
                        </div>
                      </Collapse>
                    </>
                  )}

                  <div>
                    <div><Label>{i18n.t('demoMode', i18nOpts)}</Label></div>
                    <div className="pl-4">
                      <div className="mb-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="enableDemoModeForVerified"
                              id="enableDemoModeForVerified"
                              value={!parseBoolean(form.enableDemoModeForVerified)}
                              checked={parseBoolean(form.enableDemoModeForVerified)}
                              onChange={this.onTextChange}
                            />
                            {i18n.t('enableDemoModeForVerified', i18nOpts)}
                          </Label>
                        </FormGroup>
                      </div>

                      <div className="mb-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="enableDemoModeForReserveNow"
                              id="enableDemoModeForReserveNow"
                              value={!parseBoolean(form.enableDemoModeForReserveNow)}
                              checked={parseBoolean(form.enableDemoModeForReserveNow)}
                              onChange={this.onTextChange}
                            />
                            {i18n.t('enableDemoModeForReserveNow', i18nOpts)}
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div><Label>{i18n.t('airMiles', i18nOpts)}</Label></div>
                    <div className="pl-4">
                      <div className="mb-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="enableAirMilesForVerified"
                              id="enableAirMilesForVerified"
                              value={!parseBoolean(form.enableAirMilesForVerified)}
                              checked={parseBoolean(form.enableAirMilesForVerified)}
                              onChange={this.onTextChange}
                            />
                            {i18n.t('enableAirMilesForVerified', i18nOpts)}
                          </Label>
                        </FormGroup>
                      </div>

                      <div className="mb-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="enableAirMilesForReserveNow"
                              id="enableAirMilesForReserveNow"
                              value={!parseBoolean(form.enableAirMilesForReserveNow)}
                              checked={parseBoolean(form.enableAirMilesForReserveNow)}
                              onChange={this.onTextChange}
                            />
                            {i18n.t('enableAirMilesForReserveNow', i18nOpts)}
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="enableContactPhoneRequired"
                          id="enableContactPhoneRequired"
                          value={!parseBoolean(form.enableContactPhoneRequired)}
                          checked={parseBoolean(form.enableContactPhoneRequired)}
                          onChange={this.onTextChange}
                        />
                        {i18n.t('enableContactPhoneRequired', i18nOpts)}
                      </Label>
                    </FormGroup>
                  </div>
                </>
              )}
            </Col>
            <Col lg="4" md="6" sm="8" xs="12">
              <div>
                <h3 className="mb-3">{i18n.t('excludeTraffic', i18nOpts)}</h3>
                <div>{i18n.t('yourIpAddress', i18nOpts)}</div>
                <div className="mb-4">{currentUser.userIp}</div>
                <FormGroup>
                  <Label for="ipToExclude">{i18n.t('ipToExclude', i18nOpts)}</Label>
                  <Input
                    type="textarea"
                    rows="5"
                    name="ipToExclude"
                    id="ipToExclude"
                    value={form.ipToExclude || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'ipToExclude')}
                  />
                  <InputError name="senderEmail" error={error} />
                </FormGroup>
                <div dangerouslySetInnerHTML={{ __html: i18n.t('messages.trafficMessage', i18nOpts) }} />
              </div>
            </Col>
          </Row>

          <div className="mt-5">
            <Button color="primary" className="mr-3" disabled={saving || !hasValues}>
              {saving && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.save')}
            </Button>
            <Button outline color="secondary" onClick={onCancel} className="mr-3">
              {i18n.t('buttons.cancel')}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default connect((store) => ({
  company: store.companies.company,
  currentUser: store.users.currentUser,
  currentCompany: store.companies.currentCompany
}), {
  saveCompany,
  getCompany
})(CompanyForm);
