import React, { useMemo } from 'react';
import i18n from 'i18n-js';
import {
  Col, Collapse, Form,
  FormGroup, Input, Label, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModelDropdown from './ModelDropdown';
import { isInputInvalid } from '../../ui/InputError';
import { InputError } from '../../ui';
import { formatNumber, isValidAmount } from '../../../utils/currencyUtils';
import DollarIcon from '../../../assets/icons/DollarIcon';
import {
  filterModelElevations, filterModelPackages, filterModelPalettes, filterSpecifications,
  getTotalBaths,
  getTotalBeds,
  getTotalPrice
} from '../../../utils/quickPossessionUtils';
import {
  LotMapProviders,
  QuickPossessionAvailabilities,
  QuickPossessionStatuses
} from '../../../constants';
import CommunityDropdown from './CommunityDropdown';
import {
  getQuickPossessionAvailabilityName,
  getQuickPossessionStatusName
} from '../../../utils/enumUtils';
import ElevationDropdown from './ElevationDropdown';
import PackageDropdown from './PackageDropdown';
import PaletteDropdown from './PaletteDropdown';
import SpecificationsMultiSelect from './SpecificationsMultiSelect';
import { parseBoolean } from '../../../utils/parserUtils';
import Floorplan from './Floorplan';
import ImagesUploader from '../../common/ImagesUploader';
import AddressAutocomplete from '../AddressAutocomplete';
import useIsReserveNowEnabled from '../../../hooks/useIsReserveNowEnabled';

const i18nOpts = { scope: 'components.global.quickPossessionForm.index' };

const QuickPossessionForm = ({
  form, setForm, error, onTextChange, currentCompany
}) => {
  const isNew = !form.id;
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onDropdownChange = (selectedValues) => {
    setForm((prevForm) => ({ ...prevForm, ...selectedValues }));
  };

  const onFloorPlanChange = (newValues) => {
    setForm((prevForm) => ({ ...prevForm, ...newValues }));
  };

  const onImagesChange = (fieldName, images) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: images }));
  };

  const onAddressSelected = (address) => {
    const event = { target: { name: 'address', value: address } };
    onTextChange(event);
  };

  const pricePlaceholder = useMemo(() => {
    const selectedFloorOptions = form.floorOptions || [];
    const price = getTotalPrice(form, selectedFloorOptions);
    if (isValidAmount(price)) return formatNumber(price);

    return i18n.t('price', i18nOpts);
  }, [form]);

  const bedsPlaceholder = useMemo(() => {
    const selectedFloorOptions = form.floorOptions || [];
    const beds = getTotalBeds(form, selectedFloorOptions);
    if (isValidAmount(beds)) return beds;

    return i18n.t('placeholders.beds', i18nOpts);
  }, [form]);

  const bathsPlaceholder = useMemo(() => {
    const selectedFloorOptions = form.floorOptions || [];
    const baths = getTotalBaths(form, selectedFloorOptions);
    if (isValidAmount(baths)) return baths;

    return i18n.t('placeholders.baths', i18nOpts);
  }, [form]);

  const requiredDepositAmountPlaceholder = useMemo(() => {
    const requiredDepositAmount = currentCompany.settings?.design?.fixedDepositAmount;
    if (isValidAmount(requiredDepositAmount)) return requiredDepositAmount;

    return i18n.t('placeholders.requiredDepositAmount', i18nOpts);
  }, [form]);

  const { community, model } = form;

  const elevations = useMemo(() => filterModelElevations(community, model),
    [community, model]);
  const packages = useMemo(() => filterModelPackages(community, model), [community, model]);
  const palettes = useMemo(() => filterModelPalettes(community, model), [community, model]);
  const specifications = useMemo(() => filterSpecifications(community, model), [community, model]);

  return (
    <Form>
      <Row>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="communityId">
              {i18n.t('community', i18nOpts)}
              <span title={i18n.t('labels.required')}> *</span>
            </Label>

            <CommunityDropdown form={form} onChange={onDropdownChange} />
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="modelId">
              {i18n.t('model', i18nOpts)}
              <span title={i18n.t('labels.required')}> *</span>
            </Label>

            <ModelDropdown form={form} onChange={onDropdownChange} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="squareFootage">{i18n.t('squareFootage', i18nOpts)}</Label>
            <Input
              type="number"
              name="squareFootage"
              id="squareFootage"
              value={form.squareFootage || ''}
              onChange={onTextChange}
              placeholder={i18n.t('squareFootage', i18nOpts)}
              invalid={isInputInvalid(error, 'squareFootage')}
            />
            <InputError error={error} name="squareFootage" />
          </FormGroup>
        </Col>
        <Col xs="6" sm="3">
          <FormGroup>
            <Label for="customBeds">{i18n.t('beds', i18nOpts)}</Label>
            <Input
              type="number"
              name="customBeds"
              id="customBeds"
              value={form.customBeds || ''}
              onChange={onTextChange}
              placeholder={bedsPlaceholder}
              invalid={isInputInvalid(error, 'customBeds')}
            />
            <InputError error={error} name="customBeds" />
          </FormGroup>
        </Col>
        <Col xs="6" sm="3">
          <FormGroup>
            <Label for="customBaths">{i18n.t('baths', i18nOpts)}</Label>
            <Input
              type="number"
              name="customBaths"
              id="customBaths"
              value={form.customBaths || ''}
              onChange={onTextChange}
              placeholder={bathsPlaceholder}
              invalid={isInputInvalid(error, 'customBaths')}
            />
            <InputError error={error} name="customBaths" />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="address">
          {i18n.t('address', i18nOpts)}
          <span title={i18n.t('labels.required')}> *</span>
        </Label>
        <AddressAutocomplete defaultAddress={form.address} onAddressSelected={onAddressSelected} />
      </FormGroup>

      <Row>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="availability">
              {i18n.t('availability', i18nOpts)}
              <span title={i18n.t('labels.required')}> *</span>
            </Label>
            <Input
              type="select"
              name="availability"
              id="availability"
              value={form?.availability || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'availability')}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                Object.values(QuickPossessionAvailabilities).map((a) => (
                  <option value={a} key={`quick-possession-availability-${a}`}>
                    {getQuickPossessionAvailabilityName(a)}
                  </option>
                ))
              }
            </Input>
            <InputError error={error} name="availability" />
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="customPrice">{i18n.t('price', i18nOpts)}</Label>
            <div className="input-icon-prepend">
              <DollarIcon />
            </div>
            <Input
              type="customPrice"
              name="customPrice"
              id="customPrice"
              value={form.customPrice || ''}
              onChange={onTextChange}
              placeholder={pricePlaceholder}
              invalid={isInputInvalid(error, 'customPrice')}
            />
            <InputError error={error} name="customPrice" />
          </FormGroup>
        </Col>
      </Row>

      {isReserveNowEnabled && (
        <FormGroup>
          <Label for="requiredDepositAmount">{i18n.t('requiredDepositAmount', i18nOpts)}</Label>
          <div className="input-icon-prepend">
            <DollarIcon />
          </div>
          <Input
            type="number"
            name="requiredDepositAmount"
            id="requiredDepositAmount"
            value={form.requiredDepositAmount || ''}
            onChange={onTextChange}
            placeholder={requiredDepositAmountPlaceholder}
            invalid={isInputInvalid(error, 'requiredDepositAmount')}
          />
          <InputError error={error} name="requiredDepositAmount" />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="description">{i18n.t('description', i18nOpts)}</Label>
        <Input
          type="textarea"
          name="description"
          id="description"
          rows={5}
          value={form.description || ''}
          onChange={onTextChange}
          placeholder={i18n.t('placeholders.description', i18nOpts)}
          invalid={isInputInvalid(error, 'description')}
        />
        <InputError error={error} name="description" />
      </FormGroup>

      <Row>
        <Col xs="12" sm="6">
          <FormGroup check className="mb-2">
            <Label check>
              <Input
                type="checkbox"
                name="enablePossessionDate"
                id="enablePossessionDate"
                value={!parseBoolean(form.enablePossessionDate)}
                checked={parseBoolean(form.enablePossessionDate)}
                onChange={onTextChange}
              />
              {i18n.t('enablePossessionDate', i18nOpts)}
            </Label>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <Collapse isOpen={parseBoolean(form.enablePossessionDate)}>
            <FormGroup>
              <Label for="possessionDate">{i18n.t('possessionDate', i18nOpts)}</Label>
              <Input
                type="date"
                name="possessionDate"
                id="possessionDate"
                value={form.possessionDate || ''}
                onChange={onTextChange}
              />
            </FormGroup>
          </Collapse>
        </Col>
      </Row>

      {!isNew && (
        <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="status">{i18n.t('status', i18nOpts)}</Label>
              <Input
                type="select"
                name="status"
                id="status"
                value={form.status || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'status')}
              >
                <option value="">{i18n.t('select.select')}</option>
                {Object.values(QuickPossessionStatuses).map((status) => (
                  <option value={status} key={`quick-possession-status-${status}`}>
                    {getQuickPossessionStatusName(status)}
                  </option>
                ))}
              </Input>
              <InputError error={error} name="status" />
            </FormGroup>
          </Col>
        </Row>
      )}

      {isNew && (
        <FormGroup>
          <Label for="image">{i18n.t('image', i18nOpts)}</Label>
          <ImagesUploader fieldName="image" multiple={false} onChange={onImagesChange} />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="url">{i18n.t('url', i18nOpts)}</Label>
        <Input
          type="text"
          name="url"
          id="url"
          value={form.url || ''}
          onChange={onTextChange}
          placeholder={i18n.t('placeholders.url', i18nOpts)}
          invalid={isInputInvalid(error, 'url')}
        />
        <InputError error={error} name="url" />
      </FormGroup>

      {!parseBoolean(form.notEditableOverrideFloors) && (
        <FormGroup check className="mb-2">
          <Label check>
            <Input
              type="checkbox"
              name="overrideFloors"
              id="overrideFloors"
              value={!parseBoolean(form.overrideFloors)}
              checked={parseBoolean(form.overrideFloors)}
              onChange={onTextChange}
            />
            {i18n.t('overrideFloors', i18nOpts)}
          </Label>
        </FormGroup>
      )}

      <Collapse isOpen={parseBoolean(form.overrideFloors)}>
        <FormGroup>
          <Label for="floorImages">{i18n.t('floorImages', i18nOpts)}</Label>
          <ImagesUploader fieldName="floorImages" onChange={onImagesChange} initialImages={form.initialFloorImages} />
        </FormGroup>
      </Collapse>

      {(!parseBoolean(form.overrideFloors) && model?.floors?.length > 0) && (
        <FormGroup>
          <Label for="address">{i18n.t('floorplan', i18nOpts)}</Label>
          <Floorplan form={form} onChange={onFloorPlanChange} />
        </FormGroup>
      )}

      {isNew && (
        <FormGroup>
          <Label for="images">{i18n.t('images', i18nOpts)}</Label>
          <ImagesUploader fieldName="images" onChange={onImagesChange} />
        </FormGroup>
      )}

      <Row>
        {elevations.length > 0 && (
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="elevationId">{i18n.t('elevation', i18nOpts)}</Label>

            <ElevationDropdown
              form={form}
              onChange={onDropdownChange}
              elevations={elevations}
            />
          </FormGroup>
        </Col>
        )}

        {packages.length > 0 && (
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="packageId">{i18n.t('package', i18nOpts)}</Label>

            <PackageDropdown
              form={form}
              onChange={onDropdownChange}
              packages={packages}
            />
          </FormGroup>
        </Col>
        )}

        {palettes.length > 0 && (
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="paletteId">{i18n.t('palette', i18nOpts)}</Label>

            <PaletteDropdown
              form={form}
              onChange={onDropdownChange}
              palettes={palettes}
            />
          </FormGroup>
        </Col>
        )}

        {specifications.length > 0 && (
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="specificationIds">{i18n.t('specifications', i18nOpts)}</Label>

            <SpecificationsMultiSelect
              form={form}
              onChange={onDropdownChange}
              specifications={specifications}
            />
          </FormGroup>
        </Col>
        )}
      </Row>

      <Collapse isOpen={community?.lotMapSettings?.provider === LotMapProviders.LOT_WORKS}>
        <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="lotId">{i18n.t('lotId', i18nOpts)}</Label>
              <Input
                type="text"
                name="lotId"
                id="lotId"
                value={form.lotId || ''}
                onChange={onTextChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Collapse>
    </Form>
  );
};

QuickPossessionForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  setForm: PropTypes.func,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

QuickPossessionForm.defaultProps = {
  setForm: () => {},
  error: null,
  onTextChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(QuickPossessionForm);
