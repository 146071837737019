import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Badge } from 'reactstrap';

const i18nOpts = { scope: 'components.admin.workflows.components.optionalBadge.index' };

const OptionalBadge = ({ optional }) => {
  if (!optional) return null;

  return (
    <Badge color="warning" className="p-1">
      {i18n.t('optional', i18nOpts)}
    </Badge>
  );
};

OptionalBadge.propTypes = {
  optional: PropTypes.bool
};

OptionalBadge.defaultProps = {
  optional: true
};

export default OptionalBadge;
