import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  Button, ModalFooter, Spinner, ModalBody
} from 'reactstrap';
import v from 'voca';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import HomeInfo from './HomeInfo';
import CustomerInfo from './CustomerInfo';
import useReserveNowInvitation from '../hooks/useReserveNowInvitation';
import useVerifiedInvitation from '../hooks/useVerifiedInvitation';
import { SalesInvitationTypes } from '../constants';
import CopyInviteLinkButton from '../components/CopyInviteLinkButton';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.confirmInvitation.index' };

const ConfirmInvitation = ({
  onContinue, invitationType, invitedCustomers
}) => {
  const { inviting: invitingReserveNow, sendReserveNowInvitationAsync } = useReserveNowInvitation();
  const { inviting: invitingVerified, sendVerifiedInvitationAsync } = useVerifiedInvitation();

  const isReserveNow = useMemo(
    () => invitationType === SalesInvitationTypes.RESERVE_NOW,
    [invitationType]
  );

  const hasSomeUnselectedInviteMethod = useMemo(() => invitedCustomers
    .some((ic) => v.isBlank(ic.inviteMethod)),
  [invitedCustomers]);

  const onInvite = () => {
    const sendInvitationTypeAsync = isReserveNow
      ? sendReserveNowInvitationAsync : sendVerifiedInvitationAsync;

    sendInvitationTypeAsync()
      .then(() => onContinue())
      .catch((e) => toast.error(e.message));
  };

  return (
    <>
      <ModalBody className="p-0">
        <div className="pt-0 px-3 pb-3">
          <div className="font-18 font-weight-600 mb-1">{i18n.t(`${invitationType}.title`, i18nOpts)}</div>

          {isReserveNow && <HomeInfo />}
        </div>

        <hr className="m-0" />

        <div className="p-3">
          <CustomerInfo />
        </div>
      </ModalBody>

      <ModalFooter className="gap-2">
        <div className="flex-even">
          <CopyInviteLinkButton />
        </div>
        <div className="flex-even">
          <Button
            color="primary"
            onClick={onInvite}
            block
            disabled={invitingReserveNow
              || invitingVerified
              || !invitedCustomers.length
              || hasSomeUnselectedInviteMethod}
          >
            {(invitingReserveNow || invitingVerified) && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.sendInvite', i18nOpts)}
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

ConfirmInvitation.propTypes = {
  onContinue: PropTypes.func
};

ConfirmInvitation.defaultProps = {
  onContinue: () => {}
};

export default connect((store) => ({
  invitationType: store.salesInvitation.invitationType,
  invitedCustomers: store.salesInvitation.invitedCustomers
}))(ConfirmInvitation);
