import React, { useEffect, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import styles from './DealModal.module.scss';
import { ModalNames } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import { getHome } from '../../../../store/actions/homeActions';
import { Tabs } from './utils';
import ModalCloseButton from '../../../common/ModalCloseButton';
import DealCustomerInfo from '../DealCustomerInfo';
import NavTabs from './NavTabs';
import Overview from './Overview';
import Timeline from './Timeline';

function onClose() {
  ModalService.close(ModalNames.DEAL);
}

const DealModal = ({
  opened, params, home, currentCompany, ...props
}) => {
  const { homeId } = params;
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  const loadHome = () => {
    setLoading(true);

    const variables = {
      withContact: true
    };

    props.getHome(homeId, currentCompany.id, variables)
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (opened) {
      loadHome();
      return;
    }

    setLoading(false);
    setActiveTab(Tabs.OVERVIEW);
  }, [opened]);

  if (!home) return null;

  return (
    <Modal isOpen={opened} modalClassName="right" scrollable>
      <ModalHeader close={<ModalCloseButton onClick={onClose} />} tag="div" className="pb-0">
        <DealCustomerInfo customer={home.contact} />
      </ModalHeader>
      <ModalBody className="overflow-hidden p-0">
        <div className="d-flex flex-column h-100">
          <div className="px-3 pt-3">
            <div className="mb-3">
              <NavTabs activeTab={activeTab} onChange={onTabChange} />
            </div>

            <hr className={styles.separator} />
          </div>

          <div className={classNames(styles.content, 'flex-grow-1')}>
            {loading ? (
              <div className="text-center my-5"><Spinner size="lg" /></div>
            ) : (
              <>
                {activeTab === Tabs.OVERVIEW && (
                  <Overview />
                )}

                {activeTab === Tabs.TIMELINE && (
                  <Timeline />
                )}
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

DealModal.propTypes = {};

DealModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.DEAL]?.opened || false,
  params: store.modals[ModalNames.DEAL]?.params || {},
  home: store.homes.home,
  currentCompany: store.companies.currentCompany
}), {
  getHome
})(DealModal);
